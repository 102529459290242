import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Title from "../components/title";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Loading from "../components/loading";
import { Link, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";

import GestaoService from "../../services/gestao";

const theme = createTheme();

export default function Alterar() {
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingAcao, setLoadingAcao] = React.useState(false);
  const [data, setData] = React.useState({
    id: "",
    nome: "",
    ramo: "",
    telefone: "",
    endereco: "",
  });

  let { id } = useParams();
  let history = useHistory();

  const getData = React.useCallback(async () => {
    if (!id) {
      return;
    }

    setErrors([]);
    setLoading(true);

    new GestaoService()
      .loja(id)
      .then((response) => {
        setLoading(false);
        setData(response?.data?.body);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  }, [id]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    setLoadingAcao(true);
    setErrors([]);
    event.preventDefault();

    let alterar = async () => {
      return new GestaoService()
        .alterarLoja(data.id, data)
        .then((response) => {
          setErrors([]);
          history.push("../");
        })
        .catch((err) => {
          setErrors(
            err?.response?.data?.errors || [
              {
                msg: "não foi possível continuar, tente novamente mais tarde",
              },
            ]
          );
          setLoadingAcao(false);
        });
    };

    let inserir = async () => {
      return new GestaoService()
        .inserirLoja(data)
        .then((response) => {
          setErrors([]);
          history.push("./");
        })
        .catch((err) => {
          setErrors(
            err?.response?.data?.errors || [
              {
                msg: "não foi possível continuar, tente novamente mais tarde",
              },
            ]
          );
          setLoadingAcao(false);
        });
    };

    return data.id ? await alterar() : await inserir();
  };

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <Loading loading={loading} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                sx={{ p: 2, mb: 2, display: "flex", flexDirection: "column" }}>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="none" color="none" to={id ? ".." : "."}>
                      Lojas
                    </Link>
                    <Typography color="text.primary">
                      {id ? "Alterar" : "Cadastrar"}
                    </Typography>
                  </Breadcrumbs>
                </div>
              </Paper>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Title>
                  <IconButton
                    aria-label="voltar"
                    component={Link}
                    to={id ? ".." : "."}>
                    <ArrowBackIcon />
                  </IconButton>
                  {id ? "Alterar" : "Cadastrar"} Loja
                </Title>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="nome"
                      name="nome"
                      label="Nome"
                      type="text"
                      variant="outlined"
                      autoComplete="name"
                      value={data.nome}
                      onChange={handleChange}
                      autoFocus
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="ramo"
                      name="ramo"
                      label="Ramo"
                      type="text"
                      variant="outlined"
                      autoComplete="ramo"
                      value={data.ramo}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="endereco"
                      name="endereco"
                      label="Endereço"
                      type="text"
                      variant="outlined"
                      autoComplete="address"
                      value={data.endereco}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <InputMask
                      mask="(99) 9 9999-9999"
                      maskChar=""
                      value={data.telefone}
                      onChange={handleChange}>
                      {() => (
                        <TextField
                          id="telefone"
                          name="telefone"
                          label="Telefone"
                          type="text"
                          variant="outlined"
                          autoComplete="phone"
                          fullWidth
                          required
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px",
                    }}>
                    <LoadingButton
                      type="submit"
                      loading={loadingAcao}
                      variant="contained">
                      {id ? "Alterar" : "Cadastrar"}
                    </LoadingButton>
                  </div>
                  {errors.length ? (
                    <Alert severity="error">
                      {errors.map((error) => error.msg).shift()}
                    </Alert>
                  ) : (
                    ""
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
