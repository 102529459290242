import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../components/title";

export default function Pedidos(props) {
  return (
    <React.Fragment>
      <Title>Últimos pedidos</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Número</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Produtos</TableCell>
            <TableCell>Em</TableCell>
            <TableCell>Forma de Pagamento</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.ultimosPedidos.map((pedido) => (
            <TableRow key={pedido.id}>
              <TableCell>{pedido.numero}</TableCell>
              <TableCell>{pedido.cliente.nome}</TableCell>
              <TableCell>{pedido.produtos.length}</TableCell>
              <TableCell>
                {new Date(pedido.data_recebimento).toLocaleString("pt-BR", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </TableCell>
              <TableCell>{pedido.pagamento.nome}</TableCell>
              <TableCell align="right">
                {parseFloat(pedido.pagamento.valor_total).toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="/dashboard/pedidos" sx={{ mt: 3 }}>
        Ver mais pedidos
      </Link>
    </React.Fragment>
  );
}
