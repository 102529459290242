import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loading from "../../components/loading";
import Alert from "@mui/material/Alert";

import { useHistory } from "react-router-dom";

import semImagem from "../../../assets/sem_imagem.png";
import fundoImagem from "../../../assets/fundo.jpg";

import { getConfig } from "../../../utils/utils";
import PublicaService from "../../../services/publica";

const theme = createTheme();

export default function Lojas() {
  let history = useHistory();

  const [rows, setRows] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getRows = React.useCallback(() => {
    setErrors([]);

    new PublicaService()
      .lojas()
      .then((response) => {
        setRows(response?.data?.body);
        setLoading(false);

        if (!response?.data?.body?.length) {
          setErrors([
            {
              msg: "Nenhuma loja cadastrada até o momento.",
            },
          ]);
        }
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            {
              msg: "não foi possível continuar, tente novamente mais tarde",
            },
          ]
        );
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    getRows();
  }, [getRows]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main
        style={{
          height: "100vh",
          backgroundImage: `url(${fundoImagem})`,
        }}>
        <Container sx={{ py: 8 }} maxWidth="md">
          <Typography variant="h5" sx={{ mb: 3 }}>
            Lojas
          </Typography>

          {errors.length ? (
            <Alert severity="error">
              {errors.map((error) => error.msg).shift()}
            </Alert>
          ) : (
            ""
          )}

          <Grid container spacing={2}>
            {rows.map((loja) => (
              <Grid item key={loja.id} xs={12} sm={4} md={4}>
                <Card
                  onClick={() => {
                    history.push("/totem/loja/" + loja.id);
                  }}
                  sx={{
                    minWidth: "220px",
                    minHeight: "350px",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f6f6f6",
                    borderRadius: 0,
                  }}>
                  <img
                    src={getConfig(loja.config, "logo") || semImagem}
                    alt={loja.nome}
                    width="100%"
                    height="200px"
                  />
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ textAlign: "center" }}>
                      {loja.nome}
                    </Typography>
                    <Typography
                      sx={{
                        color: "text.primary",
                        textAlign: "center",
                      }}>
                      {getConfig(loja.config, "regulamento")}
                    </Typography>

                    <Grid
                      container
                      justifyContent="space-between"
                      sx={{ mt: 2 }}>
                      <Typography
                        inline="true"
                        variant="caption"
                        sx={{ ml: 1, color: "primary.main" }}>
                        {loja.ramo}
                      </Typography>
                      <Typography
                        inline="true"
                        align="right"
                        variant="caption"
                        sx={{ mr: 1, color: "text.secondary" }}>
                        {getConfig(loja.config, "tempo_medio_entrega")}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}
