import axios from "axios";
import { API_URL, TIMEOUT } from "../constants/config";

const api = axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT,
});

api.interceptors.request.use(function (config) {
  config.headers["Authorization"] = localStorage.getItem("jwt");

  return config;
});

export default api;
