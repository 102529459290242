import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Loading from "../../components/loading";
import Produto from "./Produto";

import { useHistory, useParams } from "react-router-dom";
import { getConfig, getLogged, isActive } from "../../../utils/utils";

import semImagem from "../../../assets/sem_imagem.png";
import logoGamaDelivery from "../../../assets/logo_gamadelivery_light.png";

import PublicaService from "../../../services/publica";
import CarrinhoService from "../../../services/carrinho";

export default function Loja() {
  let history = useHistory();
  let { id } = useParams();

  const [data, setData] = React.useState({
    loja: {},
    cardapio: [],
  });
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [produtoOpcoes, setProdutoOpcoes] = React.useState(null);
  const [totalizadorCarrinho, setTotalizadorCarrinho] = React.useState({
    itens: 0,
    valor: 0,
  });
  const [categorias, setCategorias] = React.useState([]);
  const [menuCategoria, setMenuCategoria] = React.useState(null);

  const theme = createTheme({
    palette: {
      primary: {
        main: getConfig(data.loja.config, "cor_primaria")
          ? getConfig(data.loja.config, "cor_primaria")
          : "#1976d2",
      },
      secondary: {
        main: getConfig(data.loja.config, "cor_secundaria")
          ? getConfig(data.loja.config, "cor_secundaria")
          : "#125da8",
      },
    },
  });

  let abortar;
  window.addEventListener(`mousemove`, () => {
    clearTimeout(abortar);
    abortar = setTimeout(() => {
      new CarrinhoService().limpar();
      if (getLogged()) {
        history.goBack();
      } else {
        history.push("/totem");
      }
    }, 6 * 60000);
  });

  const getData = React.useCallback(() => {
    setErrors([]);

    new PublicaService()
      .loja(id)
      .then((response) => {
        setData(response?.data?.body);
        setLoading(false);

        if (!response?.data?.body?.cardapio.length) {
          setErrors([
            {
              msg: "Nenhum produto cadastrado até o momento.",
            },
          ]);
        }
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            {
              msg: "não foi possível continuar, tente novamente mais tarde",
            },
          ]
        );
        setLoading(false);
      });
  }, [id]);

  const atualizarTotalizadorCarrinho = () => {
    setTotalizadorCarrinho(new CarrinhoService().totalizadores());
  };

  React.useEffect(() => {
    getData();
    atualizarTotalizadorCarrinho();
  }, [getData]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Produto
        {...produtoOpcoes}
        loja={data.loja}
        configs={data.loja.config}
        cor_primaria={getConfig(data.loja.config, "cor_primaria")}
        cor_secundaria={getConfig(data.loja.config, "cor_secundaria")}
        callback={atualizarTotalizadorCarrinho}
      />

      {errors.length ? (
        <Alert severity="error">
          {errors.map((error) => error.msg).shift()}
        </Alert>
      ) : (
        ""
      )}

      <CssBaseline />

      <Box
        sx={{
          width: "100%",
          height: "200px",
          // to-do
          // background: `linear-gradient(0deg, ${getConfig(
          //   data.loja.config,
          //   "cor_primaria"
          // )}b8 100%, rgba(201,117,115,0) 100%), url("${getConfig(
          //   data.loja.config,
          //   "capa"
          // )}") no-repeat`,
          background: `url("${getConfig(data.loja.config, "capa")}") no-repeat`,
          filter:
            !isActive(getConfig(data.loja.config, "aberta"))
              ? "grayscale(100%)"
              : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <Grid container height="85vh">
        <Grid
          item
          xs={2}
          sx={{
            backgroundColor: `${getConfig(data.loja.config, "cor_secundaria")}`,
          }}>
          <Box
            component="nav"
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
            }}>
            {categorias.map((categoria) => (
              <Box
                component="nav"
                key={`categoria-${categoria.id}`}
                onClick={() => {
                  setProdutoOpcoes(null);
                  setMenuCategoria(categoria.id);
                }}
                sx={{
                  width: "120px",
                  minHeight: "50px",
                  backgroundColor:
                    menuCategoria && menuCategoria === categoria.id
                      ? `${getConfig(data.loja.config, "cor_primaria")}`
                      : "transparent",
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                  marginTop: 3,
                  paddingY: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  fontWeight: "medium",
                }}>
                <img
                  src={categoria.imagem || semImagem}
                  alt={categoria.nome}
                  width="44px"
                  height="44px"
                />
                <Typography
                  inline="true"
                  variant="h6"
                  sx={{
                    mt: 1,
                    textTransform: "uppercase",
                    color: "#fff",
                    fontSize: 14,
                  }}>
                  {categoria.nome}
                </Typography>
              </Box>
            ))}
          </Box>

          <div
            style={{
              width: "220px",
              position: "fixed",
              bottom: 0,
              flexDirection: "column",
              padding: 15,
            }}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "10px",
              }}>
              Desenvolvido por
            </Typography>
            <img
              src={logoGamaDelivery}
              alt="Logo Gama Delivery"
              width="100px"
            />
          </div>
        </Grid>

        <Grid item xs={10} sx={{ p: 1 }}>
          <Grid container spacing={2}>
            {data.cardapio
              .filter((categoria) => {
                if (!categoria.produtos.length) {
                  return false;
                }

                if (menuCategoria && menuCategoria !== categoria.id) {
                  return false;
                }

                return true;
              })
              .map((categoria, index) => {
                const verificarExistencia = categorias.filter(
                  (item) => item.id === categoria.id
                );
                if (!verificarExistencia.length) {
                  setCategorias((cats) => [...cats, categoria]);
                }
                /**
                 *
                 */
                return categoria.produtos.map((produto) => (
                  <Grid
                    item
                    key={`produto-${produto.id}`}
                    xs={4}
                    sx={{
                      opacity: produto.ativo === true ? 1 : 0.5,
                    }}>
                    <ButtonBase
                      disabled={
                        data.loja.nome &&
                        !isActive(getConfig(data.loja.config, "aberta"))
                      }
                      onClick={() =>
                        setProdutoOpcoes({
                          ...produto,
                          open: produto.ativo,
                          loja: data.loja,
                        })
                      }>
                      <Card
                        sx={{
                          minWidth: "200px",
                          minHeight: "360px",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#f6f6f6",
                          borderRadius: 0,
                        }}>
                        <div style={{ padding: 10 }}>
                          <img
                            src={produto.imagem || semImagem}
                            alt={produto.nome}
                            width="100%"
                            height="160px"
                          />
                        </div>
                        <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="h2"
                            sx={{ textTransform: "uppercase" }}>
                            {produto.nome}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "text.primary",
                            }}>
                            {produto.descricao}
                          </Typography>
                        </CardContent>

                        <Grid container>
                          <Grid container justifyContent="space-between">
                            <Typography inline="true" sx={{ ml: 1 }}>
                              a partir de
                            </Typography>
                            <Typography
                              inline="true"
                              align="right"
                              variant="h6"
                              sx={{ mr: 1 }}>
                              {parseFloat(produto.preco).toLocaleString(
                                "pt-br",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </ButtonBase>
                  </Grid>
                ));
              })}
          </Grid>

          <div
            style={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              paddingBottom: 10,
              display: "flex",
              alignItems: "center",
            }}
            sx={{
              mb: 3,
              pb: 3,
            }}>
            <Button
              sx={{
                width: "200px",
                height: 50,

                mr: 1,
                display: getLogged() ? "none" : "inline",
              }}
              onClick={() => {
                history.push("/totem");
              }}
              variant="contained"
              color="secondary">
              <Typography inline="true" variant="h6" color="white">
                VOLTAR
              </Typography>
            </Button>
            <Button
              sx={{
                width: "400px",
                height: 50,
              }}
              onClick={() => {
                if (totalizadorCarrinho.itens <= 0) {
                  return;
                }
                history.push("/totem/carrinho");
              }}
              variant="contained"
              color="primary">
              <Grid container>
                <Grid item xs={9}>
                  <Typography
                    sx={{ mt: 1.5 }}
                    align="left"
                    inline="true"
                    variant="h6"
                    color="white">
                    FINALIZAR PEDIDO
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}>
                  <Typography
                    align="right"
                    color="white"
                    variant="caption"
                    sx={{ mt: 0.5, textTransform: "lowercase" }}>
                    {totalizadorCarrinho.itens} ite
                    {totalizadorCarrinho.itens > 1 ? "ns" : "m"}
                  </Typography>

                  <Typography align="right" variant="h6" color="white">
                    {parseFloat(totalizadorCarrinho.valor).toLocaleString(
                      "pt-br",
                      {
                        style: "currency",
                        currency: "BRL",
                      }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </div>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
