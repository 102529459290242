import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Loading from "../components/loading";
import Alert from "@mui/material/Alert";

import Diario from "./diario.js";
import TotalizadorHoje from "./totalizador";
import Pedidos from "./pedidos";

import ControleService from "../../services/controle";

const theme = createTheme();

export default function Dashboard() {
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [relatorio, setRelatorio] = React.useState({
    ultimosPedidos: [],
    ultimosDias: [],
    totalizador: {
      valor: 0,
      data: new Date(),
    },
  });

  const getRelatorio = React.useCallback(() => {
    setErrors([]);

    new ControleService()
      .relatorioDashboard()
      .then((response) => {
        setRelatorio(response?.data?.body);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    getRelatorio();
  }, [getRelatorio]);

  return (
    <ThemeProvider theme={theme}>
      <Loading loading={loading} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {errors.length ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errors.map((error) => error.msg).shift()}
            </Alert>
          ) : (
            ""
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 240,
                }}>
                <Diario {...relatorio} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 240,
                }}>
                <TotalizadorHoje {...relatorio} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Pedidos {...relatorio} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
