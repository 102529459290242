export const tecladoPadrao = {
  default: [
    "q w e r t y u i o p {bksp}",
    "a s d f g h j k l '",
    "z x c v b n m , . ;",
    "{space}",
  ],
  shift: [
    "Q W E R T Y U I O P {bksp}",
    "A S D F G H J K L '",
    "Z X C V B N M , . ;",
    "{space}",
  ],
};

export const tecladoNumerico = {
  default: ["1 2 3", "4 5 6", "7 8 9", "0 {bksp}"],
};
