import api from "./api";

export default class ControleService {
  constructor() {
    this.api = api;
  }
  /**
   * RELATÓRIOS
   */
  async relatorioDashboard() {
    return await this.api.get("/controle/relatorios/dashboard");
  }
  /**
   * CONFIGURAÇÕES
   */
  async configs() {
    return await this.api.get("/controle/configs");
  }

  async alterarConfigs(data) {
    return await this.api.put(`/controle/configs`, data);
  }

  async configsPagamentos() {
    return await this.api.get("/controle/configs/pagamentos");
  }

  async alterarConfigsPagamentos(data) {
    return await this.api.put(`/controle/configs/pagamentos`, data);
  }
  /**
   * CATEGORIAS
   */
  async categorias() {
    return await this.api.get("/controle/categorias");
  }

  async categoria(id) {
    return await this.api.get(`/controle/categorias/${id}`);
  }

  async inserirCategoria(data) {
    return await this.api.post(`/controle/categorias`, data);
  }

  async alterarCategoria(id, data) {
    return await this.api.put(`/controle/categorias/${id}`, data);
  }

  async deletarCategoria(id) {
    return await this.api.delete(`/controle/categorias/${id}`);
  }
  /**
   * GRUPO DE OPÇÕES
   */
  async opcao(id) {
    return await this.api.get(`/controle/opcoes/${id}`);
  }

  async opcoes(idGrupoOpcoes) {
    return await this.api.get(
      `/controle/opcoes-por-grupo-opcoes/${idGrupoOpcoes}`
    );
  }

  async inserirOpcao(data) {
    return await this.api.post(`/controle/opcoes`, data);
  }

  async alterarOpcao(id, data) {
    return await this.api.put(`/controle/opcoes/${id}`, data);
  }

  async deletarOpcao(id) {
    return await this.api.delete(`/controle/opcoes/${id}`);
  }
  /**
   * GRUPO DE OPÇÕES
   */
  async grupoOpcoes() {
    return await this.api.get("/controle/grupo-opcoes");
  }

  async grupoOpcao(id) {
    return await this.api.get(`/controle/grupo-opcoes/${id}`);
  }

  async inserirGrupoOpcoes(data) {
    return await this.api.post(`/controle/grupo-opcoes`, data);
  }

  async alterarGrupoOpcoes(id, data) {
    return await this.api.put(`/controle/grupo-opcoes/${id}`, data);
  }

  async deletarGrupoOpcoes(id) {
    return await this.api.delete(`/controle/grupo-opcoes/${id}`);
  }
  /**
   * PRODUTOS
   */
  async produtos() {
    return await this.api.get("/controle/produtos");
  }

  async produto(id) {
    return await this.api.get(`/controle/produtos/${id}`);
  }

  async inserirProduto(data) {
    return await this.api.post(`/controle/produtos`, data);
  }

  async alterarProduto(id, data) {
    return await this.api.put(`/controle/produtos/${id}`, data);
  }

  async deletarProduto(id) {
    return await this.api.delete(`/controle/produtos/${id}`);
  }
  /**
   * PEDIDOS
   */
  async pedidos() {
    return await this.api.get("/controle/pedidos");
  }

  async pedido(id) {
    return await this.api.get(`/controle/pedidos/${id}`);
  }

  async alterarPedido(id, data) {
    return await this.api.patch(`/controle/pedidos/${id}`, data);
  }
}
