import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Title from "../components/title";
import { useHistory } from "react-router-dom";

import AutenticacaoService from "../../services/autenticacao";

const theme = createTheme();

export default function MeusDados(props) {
  let history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const handleSubmit = (event) => {
    setLoading(true);
    setErrors([]);
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    new AutenticacaoService()
      .alterarSenha({
        senha: data.get("senha"),
        novaSenha: data.get("novaSenha"),
        confirmacaoNovaSenha: data.get("confirmacaoNovaSenha"),
      })
      .then((response) => {
        history.push("/sair");
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Title>Meus Dados</Title>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="id"
                      name="id"
                      label="Identificação"
                      fullWidth
                      variant="outlined"
                      value={props.id}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="nome"
                      name="nome"
                      label="Nome"
                      fullWidth
                      variant="outlined"
                      value={props.nome}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      name="email"
                      label="E-mail"
                      fullWidth
                      variant="outlined"
                      value={props.email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="telefone"
                      name="telefone"
                      label="Telefone"
                      fullWidth
                      variant="outlined"
                      value={props.telefone}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}>
                  <Title>Alterar Senha</Title>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="senha"
                        name="senha"
                        label="Senha antiga"
                        type="password"
                        variant="outlined"
                        autoComplete="current-password"
                        autoFocus
                        fullWidth
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="novaSenha"
                        name="novaSenha"
                        label="Nova senha"
                        type="password"
                        variant="outlined"
                        autoComplete="new-password"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="confirmacaoNovaSenha"
                        name="confirmacaoNovaSenha"
                        label="Confirmação da nova senha"
                        type="password"
                        variant="outlined"
                        autoComplete="new-password-confirm"
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px",
                    }}>
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      variant="contained">
                      Alterar
                    </LoadingButton>
                  </div>
                </Box>

                {errors.length ? (
                  <Alert severity="error">
                    {errors.map((error) => error.msg).shift()}
                  </Alert>
                ) : (
                  ""
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
