import * as React from "react";
import PropTypes from "prop-types";

import Snackbar from "@mui/material/Snackbar";

function Message(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(props.text ? true : false);
  }, [props]);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
      message={props.text}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    />
  );
}

Message.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Message;
