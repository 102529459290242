import api from "./api";

export default class PublicaService {
  constructor() {
    this.api = api;
  }

  async lojas() {
    return await this.api.get(`/api/v1/lojas`);
  }

  async loja(id) {
    return await this.api.get(`/api/v1/lojas/${id}`);
  }

  async finalizarPedidos(pedidos) {
    return await this.api.post(`/api/v1/pedidos/multi`, pedidos);
  }

  async buscarStatusPedido(id) {
    return await this.api.get(`/api/v1/pedidos/status-pagamento/${id}`);
  }

  async cancelarPagamento(id) {
    return await this.api.delete(`/api/v1/pedidos/cancelar-pagamento/${id}`);
  }

  async imprimirPedido(id, tipo, colunas) {
    return await this.api.get(
      `/api/v1/pedidos/impressao/${id}?tipo=${tipo}&colunas=${colunas}`
    );
  }
}
