import api from "./api";

export default class AutenticacaoService {
  constructor() {
    this.api = api;
  }

  async login(dados) {
    return await this.api.post("/autenticacao/login", dados);
  }

  async detalhes() {
    return await this.api.get("/autenticacao/detalhes");
  }

  async recuperarSenha(dados) {
    return await this.api.post("/autenticacao/recuperar-senha", dados);
  }

  async alterarSenha(dados) {
    return await this.api.patch("/autenticacao/alterar-senha", dados);
  }
}
