export const toBase64 = (padrao, file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    if (!file) {
      return resolve(padrao);
    }

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const validarImagem = (arquivos) => {
  let qtd = Object.keys(arquivos);

  if (qtd.length <= 0) {
    return null;
  }

  let arquivo = arquivos[0];

  if (
    !arquivo.name.match(
      /(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG|\.webp|\.WEBP)$/
    )
  ) {
    throw new Error("O formato da imagem enviada é inválido");
  }

  if (Math.round(arquivo.size / 1024) >= 5096) {
    throw new Error("A imagem enviada é muito grande");
  }

  return arquivo;
};

export const imprimir = (arrImp) => {
  let impressao = window.open(
    "",
    `${arrImp.length} - Impressão`,
    "width=800, height=600, resizable=no, titlebar=no, location=no, toolbar=no, menubar=no, scrollbars=no"
  );
  impressao.document.write(`
    <html>
    <head>
    <title>${arrImp.length} - Impressão</title>
    <style>
      body {
          white-space: pre-wrap;
          font-size: 28px;
          font-family: monospace;
          margin: 0px;
          padding: 0px;
          height: 100%;
      }
    </style>
    </head>

    <body>${arrImp.join("\n")}</body>

    </html>
  `);

  impressao.focus();

  setTimeout(() => {
    impressao.print();
    impressao.close();
  }, 1000);
};

export const getConfig = (config, campo) => {
  let valor = "";

  if (Array.isArray(config)) {
    config.map((cfg) => {
      if (cfg.campo === campo) {
        try {
          valor = JSON.parse(cfg.valor);
        } catch (e) {
          valor = cfg.valor;
        }
      }
      return cfg;
    });
  }

  return valor;
};

export const groupBy = (items, key) =>
  items.reduce((result, item, index) => {
    item.index = index;
    return {
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    };
  }, {});

export const getLogged = () => {
  return localStorage.getItem("jwt") ? true : false;
};

export const isActive = (c) => {
  return c > 0 ? true : false;
}
