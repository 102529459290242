import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Title from "../components/title";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Loading from "../components/loading";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";

import ControleService from "../../services/controle";

const theme = createTheme();

export default function Configuracoes() {
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingAcao, setLoadingAcao] = React.useState(false);
  const [data, setData] = React.useState({
    whatsapp_token: "",
    whatsapp_instancia: "",
  });

  const parseData = React.useCallback((newData) => {
    let parsedData = {};

    newData.forEach((item) => {
      parsedData[item.campo] = item.valor;
    });

    return parsedData;
  }, []);

  const unparseData = () => {
    let unparseData = [];

    for (let campo of Object.keys(data)) {
      const valor = data[campo];
      unparseData.push({
        campo: campo,
        valor: valor,
      });
    }

    return unparseData;
  };

  const getData = React.useCallback(async () => {
    setErrors([]);
    setLoading(true);

    return new ControleService()
      .configs()
      .then((response) => {
        setLoading(false);
        setData(parseData(response?.data?.body));
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  }, [parseData]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingAcao(true);
    setErrors([]);

    return new ControleService()
      .alterarConfigs(unparseData())
      .then((response) => {
        setErrors([]);
        setLoadingAcao(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoadingAcao(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <Loading loading={loading} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Title>Configurações</Title>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="whatsapp_token"
                      name="whatsapp_token"
                      label="Token"
                      type="text"
                      variant="outlined"
                      autoComplete="whatsapp_token"
                      value={data.whatsapp_token}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="whatsapp_instancia"
                      name="whatsapp_instancia"
                      label="Instância"
                      type="text"
                      variant="outlined"
                      autoComplete="whatsapp_instancia"
                      value={data.whatsapp_instancia}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px",
                    }}>
                    <LoadingButton
                      type="submit"
                      loading={loadingAcao}
                      variant="contained">
                      Alterar
                    </LoadingButton>
                  </div>
                  {errors.length ? (
                    <Alert severity="error">
                      {errors.map((error) => error.msg).shift()}
                    </Alert>
                  ) : (
                    ""
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
