import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Title from "../components/title";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Loading from "../components/loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

import ControleService from "../../services/controle";

const theme = createTheme();

export default function ConfiguracoesPagamento() {
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingAcao, setLoadingAcao] = React.useState(false);
  const [data, setData] = React.useState({
    id: "",
    tipo_doc: "",
    num_doc: "",
    pix_tipo: "",
    pix_chave: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingAcao(true);
    setErrors([]);

    return new ControleService()
      .alterarConfigsPagamentos(data)
      .then((response) => {
        setErrors([]);
        setLoadingAcao(false);

        const { body: dados } = response.data;
        setData({ ...data, id: dados.id });
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoadingAcao(false);
      });
  };

  const getData = React.useCallback(async () => {
    setErrors([]);
    setLoading(true);

    return new ControleService()
      .configsPagamentos()
      .then((response) => {
        setLoading(false);

        const { body: dados } = response.data;
        setData({
          id: dados.externalId,
          tipo_doc: dados.documentType,
          num_doc: dados.document,
          pix_tipo: dados.pixKeyType,
          pix_chave: dados.pixKey,
        });
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <Loading loading={loading} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Title>Pagamentos</Title>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="id"
                      name="id"
                      label="Identificação"
                      type="text"
                      variant="outlined"
                      autoComplete="regulamento"
                      value={data.id}
                      onChange={handleChange}
                      fullWidth
                      required
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel>Tipo de documento</InputLabel>
                      <Select
                        id="tipo_doc"
                        name="tipo_doc"
                        onChange={handleChange}
                        value={data.tipo_doc}
                        disabled={data.id ? true : false}
                        label="Tipo de documento"
                        autoWidth
                        autoFocus
                        required>
                        <MenuItem value="cpf">CPF</MenuItem>
                        <MenuItem value="cnpj">CNPJ</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="num_doc"
                      name="num_doc"
                      label="Documento"
                      type="text"
                      variant="outlined"
                      autoComplete="num_doc"
                      value={data.num_doc}
                      onChange={handleChange}
                      disabled={data.id ? true : false}
                      fullWidth
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel>Pix - Tipo de chave</InputLabel>
                      <Select
                        id="pix_tipo"
                        name="pix_tipo"
                        onChange={handleChange}
                        value={data.pix_tipo}
                        label="Tipo de documento"
                        autoWidth
                        required>
                        <MenuItem value="1">CPF</MenuItem>
                        <MenuItem value="2">CNPJ</MenuItem>
                        <MenuItem value="3">E-mail</MenuItem>
                        <MenuItem value="4">Telefone</MenuItem>
                        <MenuItem value="5">Aleatória</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="pix_chave"
                      name="pix_chave"
                      label="Pix - Chave"
                      type="text"
                      variant="outlined"
                      autoComplete="pix_chave"
                      value={data.pix_chave}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px",
                    }}>
                    <LoadingButton
                      type="submit"
                      loading={loadingAcao}
                      variant="contained">
                      Alterar
                    </LoadingButton>
                  </div>
                  {errors.length ? (
                    <Alert severity="error">
                      {errors.map((error) => error.msg).shift()}
                    </Alert>
                  ) : (
                    ""
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
