export default class CarrinhoService {
  listar() {
    return JSON.parse(localStorage.getItem("carrinho")) || [];
  }

  adicionar(loja, produto) {
    if (!loja || !produto) {
      return false;
    }

    const carrinho = this.listar();
    carrinho.push({ ...loja, data: new Date(), produto: produto });

    localStorage.setItem("carrinho", JSON.stringify(carrinho));

    return true;
  }

  alterarQuantidade(index, operacao) {
    let carrinho = this.listar();
    let item = carrinho[index];

    switch (operacao) {
      case "-":
        item.produto.quantidade -= 1;
        if (item.produto.quantidade <= 0) {
          return false;
        }
        break;
      case "+":
        item.produto.quantidade += 1;
        if (item.produto.quantidade > 10) {
          return false;
        }
        break;

      default:
        return false;
    }

    item.produto.preco_total =
      (item.produto.preco_unitario + item.produto.preco_unitario_opcoes) *
      item.produto.quantidade;

    localStorage.setItem("carrinho", JSON.stringify(carrinho));

    return carrinho;
  }

  remover(index) {
    const carrinho = this.listar();

    const removido = carrinho.splice(index, 1);
    if (!removido.length) {
      return false;
    }

    localStorage.setItem("carrinho", JSON.stringify(carrinho));

    return carrinho;
  }

  totalizadores() {
    const carrinho = this.listar();

    return {
      valor: carrinho.reduce(
        (total, item) => (total += item.produto.preco_total),
        0
      ),
      itens: carrinho.reduce(
        (total, item) => (total += item.produto.quantidade),
        0
      ),
    };
  }

  limpar() {
    localStorage.setItem("carrinho", JSON.stringify([]));

    return [];
  }
}
