import * as React from "react";
import { useHistory } from "react-router-dom";

export default function Sair() {
  let history = useHistory();

  const handle = () => {
    localStorage.removeItem("jwt");
    history.push("/login");
  };

  React.useEffect(handle, [history]);

  return <div />;
}
