import * as React from "react";
import PropTypes from "prop-types";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Loading(props) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

Loading.propTypes = {
  loading: PropTypes.bool,
};

export default Loading;
