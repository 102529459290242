import api from "./api";

export default class GestaoService {
  constructor() {
    this.api = api;
  }
  /**
   * LOJAS
   */
  async lojas() {
    return await this.api.get("/gestao/lojas");
  }

  async loja(id) {
    return await this.api.get(`/gestao/lojas/${id}`);
  }

  async alterarLoja(id, data) {
    return await this.api.put(`/gestao/lojas/${id}`, data);
  }

  async inserirLoja(data) {
    return await this.api.post(`/gestao/lojas`, data);
  }

  async deletarLoja(id) {
    return await this.api.delete(`/gestao/lojas/${id}`);
  }
  /**
   * USUÁRIOS
   */
  async usuarios() {
    return await this.api.get("/gestao/usuarios");
  }

  async usuario(id) {
    return await this.api.get(`/gestao/usuarios/${id}`);
  }

  async alterarUsuario(id, data) {
    return await this.api.put(`/gestao/usuarios/${id}`, data);
  }

  async inserirUsuario(data) {
    return await this.api.post(`/gestao/usuarios`, data);
  }

  async deletarUsuario(id) {
    return await this.api.delete(`/gestao/usuarios/${id}`);
  }
  /**
   * PEDIDOS
   */
  async deletarPedido(id) {
    return await this.api.delete(`/gestao/pedidos/${id}`);
  }
}
