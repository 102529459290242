import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import PrintIcon from "@mui/icons-material/Print";
import { MenuControle, menuGestao, menuAvancado } from "./menuItems";
import Loading from "../components/loading";

import { Switch, Route, Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

import AutenticacaoService from "../../services/autenticacao";

import Dashboard from "../Dashboard/Dashboard";
import UserDetails from "../MeusDados/MeusDados";
import Lojas from "../Lojas/Lojas";
import LojasAlterar from "../Lojas/Alterar";
import Usuarios from "../Usuarios/Usuarios";
import UsuariosAlterar from "../Usuarios/Alterar";
import Categorias from "../Categorias/Categorias";
import CategoriasAlterar from "../Categorias/Alterar";
import GrupoOpcoes from "../GrupoOpcoes/GrupoOpcoes";
import GrupoOpcoesAlterar from "../GrupoOpcoes/Alterar";
import Opcoes from "../Opcoes/Opcoes";
import OpcoesAlterar from "../Opcoes/Alterar";
import Produtos from "../Produtos/Produtos";
import ProdutosAlterar from "../Produtos/Alterar";
import Pedidos from "../Pedidos/Pedidos";
import Configuracoes from "../Configuracoes/Configuracoes";
import ConfiguracoesImpressao from "../Configuracoes/Impressao";
import ConfiguracoesWhatsapp from "../Configuracoes/Whatsapp";
import ConfiguracoesPagamentos from "../Configuracoes/Pagamentos";

import logoGamaDelivery from "../../assets/logo_gamadelivery.png";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const theme = createTheme();

export default function Inicio() {
  let history = useHistory();

  const [open, setOpen] = React.useState(true);
  const [user, setUser] = React.useState({
    id: "",
    nome: "",
    email: "",
    telefone: "",
    nivel: 0,
    loja: {
      id: "",
      nome: "",
    },
  });
  const [loading, setLoading] = React.useState(true);

  const toggleDrawer = async () => {
    setOpen(!open);
  };

  const getUserDetails = () => {
    setLoading(true);

    new AutenticacaoService()
      .detalhes()
      .then((response) => {
        setUser(response?.data?.body);
        setLoading(false);
      })
      .catch((err) => {
        history.push("/sair");
      });
  };

  React.useEffect(getUserDetails, [history]);

  return (
    <ThemeProvider theme={theme}>
      <Loading loading={loading} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}>
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}>
              {user.loja.nome}
            </Typography>
            <IconButton
              color="inherit"
              component={Link}
              to="/dashboard/configuracoes/impressao">
              <PrintIcon />
            </IconButton>
            <IconButton
              color="inherit"
              component={Link}
              to="/dashboard/meus-dados">
              <PeopleIcon />
            </IconButton>
            <IconButton color="inherit" component={Link} to="/sair">
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}>
            <div
              style={{
                marginRight: "20px",
              }}>
              <img
                src={logoGamaDelivery}
                width="132px"
                alt="logo gama delivery"
              />
            </div>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MenuControle />
            {user && user.nivel === 1 ? menuGestao : null}
            {menuAvancado(user)}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}>
          <Toolbar />
          <Switch>
            {/* TODOS */}
            <Route exact path="/dashboard" component={Dashboard} />
            <Route
              path="/dashboard/meus-dados"
              render={(props) => <UserDetails {...user} />}
            />
            {/* LOJISTA */}
            <Route
              path="/dashboard/configuracoes/impressao"
              component={user ? ConfiguracoesImpressao : null}
            />
            <Route
              path="/dashboard/configuracoes/pagamentos"
              component={user ? ConfiguracoesPagamentos : null}
            />
            <Route
              path="/dashboard/configuracoes/whatsapp"
              component={user ? ConfiguracoesWhatsapp : null}
            />
            <Route
              path="/dashboard/configuracoes"
              component={user ? Configuracoes : null}
            />
            <Route
              path="/dashboard/pedidos"
              render={() => <Pedidos {...user} />}
            />
            <Route
              path="/dashboard/categorias/alterar/:id?"
              component={user ? CategoriasAlterar : null}
            />
            <Route
              path="/dashboard/categorias"
              component={user ? Categorias : null}
            />
            <Route
              path="/dashboard/grupo-opcoes/alterar/:id?"
              component={user ? GrupoOpcoesAlterar : null}
            />
            <Route
              path="/dashboard/grupo-opcoes"
              component={user ? GrupoOpcoes : null}
            />
            <Route
              path="/dashboard/opcoes/:idGrupoOpcoes/alterar/:id?"
              component={user ? OpcoesAlterar : null}
            />
            <Route
              path="/dashboard/opcoes/:idGrupoOpcoes"
              component={user ? Opcoes : null}
            />
            <Route
              path="/dashboard/produtos/alterar/:id?"
              component={user ? ProdutosAlterar : null}
            />
            <Route
              path="/dashboard/produtos"
              component={user ? Produtos : null}
            />
            {/* GESTOR */}
            <Route
              path="/dashboard/lojas/alterar/:id?"
              component={user && user.nivel === 1 ? LojasAlterar : null}
            />
            <Route
              path="/dashboard/lojas"
              component={user && user.nivel === 1 ? Lojas : null}
            />
            <Route
              path="/dashboard/usuarios/alterar/:id?"
              component={user && user.nivel === 1 ? UsuariosAlterar : null}
            />
            <Route
              path="/dashboard/usuarios"
              component={user && user.nivel === 1 ? Usuarios : null}
            />
            <Redirect from="*" to="/" />
          </Switch>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
