import React from "react";
import ReactDOM from "react-dom";

import Inicio from "./pages/Inicio/Inicio";
import Entrar from "./pages/Autenticacao/Entrar";
import Sair from "./pages/Autenticacao/Sair";

import TotemLojas from "./pages/Totem/Lojas/Lojas";
import TotemLoja from "./pages/Totem/Loja/Loja";
import TotemCarrinho from "./pages/Totem/Carrinho/Carrinho";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { getLogged } from "./utils/utils";

function PublicRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !getLogged() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        getLogged() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/entrar",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

ReactDOM.render(
  <Router>
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return getLogged() ? (
            <Redirect to="/dashboard" />
          ) : (
            <Redirect to="/entrar" />
          );
        }}
      />
      <Route path="/totem/loja/:id" component={TotemLoja} />
      <Route path="/totem/carrinho" component={TotemCarrinho} />
      <PublicRoute path="/totem" component={TotemLojas} />
      <PublicRoute path="/entrar" component={Entrar} />
      <PrivateRoute path="/dashboard" component={Inicio} />
      <PrivateRoute path="/sair" component={Sair} />
      <Redirect from="*" to="/" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
