import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Title from "../components/title";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

import { useLocalStorage } from "../../utils/useLocalStorage";

const theme = createTheme();

export default function ConfiguracoesImpressao() {
  const [colunas, setColunas] = useLocalStorage("config_imp_colunas", "60");

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Title>Impressora</Title>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="colunas"
                      name="colunas"
                      label="Colunas da impressora"
                      type="number"
                      variant="outlined"
                      autoComplete="colunas"
                      value={colunas}
                      onChange={(event) => {
                        setColunas(
                          event.target.value > 0 ? event.target.value : 60
                        );
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
