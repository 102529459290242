import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Title from "../components/title";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import StyleIcon from "@mui/icons-material/Style";
import Tooltip from "@mui/material/Tooltip";

import { GRID_DEFAULT_LOCALE_TEXT } from "../../constants/dataGrid";
import ControleService from "../../services/controle";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
  },
});

export default function GrupoOpcoes() {
  let { path } = useRouteMatch();

  let history = useHistory();

  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);

  const columns = [
    { field: "nome", headerName: "Nome", width: 300 },
    {
      field: "obrigatorio",
      headerName: "Obrigatório",
      type: "boolean",
      width: 150,
    },
    { field: "min", headerName: "Mínimo", width: 150 },
    { field: "max", headerName: "Máximo", width: 150 },
    { field: "ativo", headerName: "Ativo", type: "boolean", width: 150 },
    {
      field: "actions",
      type: "actions",
      width: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Remover">
              <DeleteIcon />
            </Tooltip>
          }
          label="Remover"
          onClick={() => remover(params.row.id)}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Opções">
              <StyleIcon />
            </Tooltip>
          }
          label="Opções"
          onClick={() => history.push(`/dashboard/opcoes/${params.row.id}`)}
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label="Alterar"
          component={Link}
          to={path + "/alterar/" + params.row.id}
          showInMenu
        />,
        <GridActionsCellItem
          icon={params.row.ativo ? <DoDisturbOffIcon /> : <DoDisturbOnIcon />}
          label={params.row.ativo ? "Desativar" : "Ativar"}
          onClick={() => alterar(params.row.id, params.row)}
          showInMenu
        />,
      ],
    },
  ];

  const getRows = React.useCallback(() => {
    setErrors([]);

    new ControleService()
      .grupoOpcoes()
      .then((response) => {
        setRows(response?.data?.body);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  }, []);

  const alterar = (id, dados) => {
    setErrors([]);
    setLoading(true);

    dados.ativo = !dados.ativo;

    new ControleService()
      .alterarGrupoOpcoes(id, dados)
      .then((response) => {
        getRows();
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  const remover = (id) => {
    setErrors([]);
    setLoading(true);

    new ControleService()
      .deletarGrupoOpcoes(id)
      .then((response) => {
        getRows();
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getRows();
  }, [getRows]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {errors.length ? (
                  <Alert severity="error">
                    {errors.map((error) => error.msg).shift()}
                  </Alert>
                ) : (
                  ""
                )}
                <Title>
                  Grupo de Opções
                  <IconButton
                    aria-label="adicionar"
                    component={Link}
                    to={path + "/alterar"}>
                    <AddIcon />
                  </IconButton>
                </Title>
                <div style={{ height: 640, width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    disableRowSelectionOnClick={true}
                    disableDensitySelector={true}
                    disableColumnSelector={true}
                    rowSelection={false}
                    localeText={GRID_DEFAULT_LOCALE_TEXT}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                    }}
                    pageSizeOptions={[10, 50, 100]}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
