import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PixIcon from "@mui/icons-material/Pix";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StyleIcon from "@mui/icons-material/Style";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import HouseIcon from "@mui/icons-material/House";

import { useLocalStorage } from "../../../utils/useLocalStorage";
import { tecladoPadrao } from "../../../utils/teclado";
import { getConfig, isActive } from "../../../utils/utils";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default function PaymentForm(props) {
  const [formaDePagamento, setFormaDePagamento] = useLocalStorage(
    "totem_cliente_forma_de_pagamento",
    ""
  );
  const [formaDeConsumo, setFormaDeConsumo] = useLocalStorage(
    "totem_cliente_forma_de_consumo",
    ""
  );
  const [observacao, setObservacao] = useLocalStorage(
    "totem_cliente_observacao",
    ""
  );
  const [tecladoLayoutNome, setTecladoLayoutNome] = React.useState("shift");
  const [tecladoLayout, setTecladoLayout] = React.useState({});
  const [teclado, setTeclado] = React.useState("");

  const theme = useTheme();

  const onChange = (input) => {
    setTecladoLayoutNome("default");

    switch (teclado) {
      case "observacao":
        setObservacao(input);
        break;
      default:
        setTeclado("");
        break;
    }
  };

  const onKeyPress = (button) => {
    if (button === "{shift}") {
      handleShift();
    }
  };

  const handleShift = () => {
    setTecladoLayoutNome(tecladoLayoutNome === "default" ? "shift" : "default");
  };

  const alterarCampoTeclado = (campo) => {
    if (!isActive(getConfig(props.configs, "teclado"))) {
      console.error("O teclado inteligente foi desativado.");
      return;
    }
    /**
     *
     */
    setTeclado("");
    /**
     *
     */
    switch (campo) {
      default:
        setTecladoLayout(tecladoPadrao);
        setTecladoLayoutNome("shift");
        break;
    }
    /**
     *
     */
    setTimeout(() => {
      setTeclado(campo);
    }, 200);
  };

  function getIcon(item) {
    switch (item) {
      /*** pagamento ***/
      case "Pix":
        return <PixIcon />;
      case "Dinheiro":
        return <AttachMoneyIcon />;
      case "Cartão de Crédito":
      case "Cartão de Débito":
      case "Vale Alimentação":
      case "Vale Refeição":
        return <CreditCardIcon />;
      case "Outra":
        return <StyleIcon />;
      /*** consumo ***/
      case "No local":
        return <FmdGoodIcon />;
      case "Para viagem":
        return <HouseIcon />;
      default:
        return <CheckCircleOutlineIcon />;
    }
  }

  function RadioOpcoesPagamento({ items }) {
    return (
      <Grid
        container
        spacing={2}
        style={{
          margin: 15,
        }}>
        {items.map((item) => (
          <Card
            sx={{ display: "flex" }}
            style={{
              backgroundColor:
                formaDePagamento !== item
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              color: "#FFF",
              width: 250,
              height: 100,
              margin: 5,
            }}
            onClick={() => setFormaDePagamento(item)}
            key={item}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
              }}>
              <Typography
                component="div"
                variant="h6"
                sx={{
                  margin: 1,
                  marginTop: 2,
                }}>
                {getIcon(item)}
              </Typography>
              <Typography component="div" variant="h6">
                {item}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Grid>
    );
  }

  function RadioOpcoesConsumo({ items }) {
    return (
      <Grid
        container
        spacing={2}
        style={{
          margin: 15,
        }}>
        {items.map((item) => (
          <Card
            sx={{ display: "flex" }}
            style={{
              backgroundColor:
                formaDeConsumo !== item
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              color: "#FFF",
              alignItems: "center",
              width: 250,
              height: 100,
              margin: 5,
              wordBreak: "break-word",
            }}
            onClick={() => setFormaDeConsumo(item)}
            key={item}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
              }}>
              <Typography
                component="div"
                variant="h6"
                sx={{
                  margin: 1,
                  marginTop: 2,
                }}>
                {getIcon(item)}
              </Typography>
              <Typography component="div" variant="h6">
                {item}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Informações de pagamento
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="forma-pagamento-label">Forma de pagamento</FormLabel>
            <RadioOpcoesPagamento
              items={getConfig(props.configs, "formasDePagamento")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="forma-consumo-label">Forma de consumo</FormLabel>
            <RadioOpcoesConsumo items={["No local", "Para viagem"]} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            id="observacao"
            label="Observações"
            type="text"
            name="observacao"
            autoComplete="observacao"
            placeholder="Informações no que diz respeito a retirada do pedido"
            variant="outlined"
            value={observacao}
            onChange={(event) => {
              setObservacao(event.target.value);
            }}
            onClick={(event) => {
              alterarCampoTeclado(event.target.name);
            }}
            size="large"
            multiline
            fullWidth
          />
        </Grid>
      </Grid>

      {teclado ? (
        <Box sx={{ mt: 5 }}>
          <Keyboard
            layoutName={tecladoLayoutNome}
            layout={tecladoLayout}
            inputName={teclado}
            onChange={onChange}
            onKeyPress={onKeyPress}
            disableButtonHold={true}
          />
        </Box>
      ) : null}
    </React.Fragment>
  );
}
