import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";

import { groupBy } from "../../../utils/utils";

import CarrinhoService from "../../../services/carrinho";

export default function ProdutosForm(props) {
  let total = {
    valor: 0,
    quantidade: 0,
  };

  const [errors, setErrors] = React.useState([]);
  const [carrinhos, setCarrinhos] = React.useState([]);

  const getCarrinho = () => {
    const listar = new CarrinhoService().listar();

    setCarrinhos(groupBy(listar, "nome"));
    setErrors([]);

    if (!listar.length) {
      setErrors([
        {
          msg: "Nenhum produto adicionado no carrinho até o momento.",
        },
      ]);
    }
  };

  const removerCarrinho = (index) => {
    const remover = new CarrinhoService().remover(index);
    if (!remover) {
      setErrors([
        {
          msg: "Não foi possível remover o produto do carrinho.",
        },
      ]);
      return;
    }

    getCarrinho();
  };

  const alterarQuantidade = (index, operacao) => {
    const alterar = new CarrinhoService().alterarQuantidade(index, operacao);
    if (!alterar) {
      setErrors([
        {
          msg: "Não foi possível alterar a quantidade do produto do carrinho.",
        },
      ]);
      return;
    }

    getCarrinho();
  };

  React.useEffect(() => {
    getCarrinho();
  }, []);

  return (
    <React.Fragment>
      {Object.keys(carrinhos).map((loja, index) => (
        <div key={index}>
          <Typography
            variant="h6"
            color="primary"
            gutterBottom
            sx={{ mt: 1, mb: 1 }}>
            {loja}
          </Typography>

          <List disablePadding>
            {carrinhos[loja].map((carrinho) => (
              <ListItem key={carrinho.index} sx={{ py: 1, px: 0 }}>
                <ListItemText
                  primary={`${carrinho.produto.quantidade}x ${carrinho.produto.nome}`}
                  secondary={
                    carrinho.produto.opcoes
                      .map((opcao) => `${opcao.quantidade}x ${opcao.nome}`)
                      .join(" / ") +
                    (carrinho.produto.observacao
                      ? `Observações: ${carrinho.produto.observacao}`
                      : "")
                  }
                  key={carrinho.id}
                />

                <div style={{ marginRight: 15 }}>
                  <IconButton
                    edge="start"
                    aria-label="remover"
                    onClick={() => alterarQuantidade(carrinho.index, "-")}>
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    type="text"
                    size="small"
                    value={carrinho.produto.quantidade}
                    style={{ width: 50 }}
                    InputProps={{
                      readOnly: true,
                      inputProps: {
                        style: { textAlign: "center" },
                      },
                    }}
                  />
                  <IconButton
                    edge="end"
                    aria-label="adicionar"
                    onClick={() => alterarQuantidade(carrinho.index, "+")}>
                    <AddIcon />
                  </IconButton>
                </div>

                <div
                  style={{
                    width: 100,
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <Typography variant="body2">
                    {parseFloat(carrinho.produto.preco_total).toLocaleString(
                      "pt-br",
                      {
                        style: "currency",
                        currency: "BRL",
                      }
                    )}
                  </Typography>
                </div>

                <IconButton
                  onClick={() => removerCarrinho(carrinho.index)}
                  edge="end">
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}

            <ListItem sx={{ py: 1, px: 0 }}>
              <ListItemText primary="Total a pagar" />
              <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                {parseFloat(
                  carrinhos[loja].reduce((accumulator, carrinho) => {
                    total.valor += carrinho.produto.preco_total;
                    total.quantidade += carrinho.produto.quantidade;
                    return accumulator + carrinho.produto.preco_total;
                  }, 0)
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Typography>
            </ListItem>
          </List>
        </div>
      ))}

      {Object.keys(carrinhos).length > 1 ? (
        <Typography
          variant="subtitle1"
          align="right"
          sx={{ fontWeight: 700, mt: 2, mb: 2 }}>
          {parseFloat(total.valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </Typography>
      ) : null}

      {errors.length ? (
        <Alert severity="error">
          {errors.map((error) => error.msg).shift()}
        </Alert>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
