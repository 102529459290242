import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Alert from "@mui/material/Alert";
import Loading from "../../components/loading";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

import ProdutosForm from "./ProdutosForm";
import DadosForm from "./DadosForm";
import PagamentoForm from "./PagamentoForm";
import Pix from "./Pix";

import { useLocalStorage } from "../../../utils/useLocalStorage";
import { imprimir, groupBy, getLogged, getConfig } from "../../../utils/utils";

import PublicaService from "../../../services/publica";
import ControleService from "../../../services/controle";
import CarrinhoService from "../../../services/carrinho";

export default function Carrinho() {
  let history = useHistory();

  const [colunas] = useLocalStorage("config_imp_colunas", "60");
  const [activeStep, setActiveStep] = React.useState(0);
  const [timer, setTimer] = React.useState(10);
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [finalizados, setFinalizados] = React.useState([]);
  const [modalMaisLojas, setModalMaisLojas] = React.useState(false);
  const [configs, setConfigs] = React.useState({});
  const [pagamento, setPagamento] = React.useState({
    modal: false,
    id: "",
    formaDePagamento: "",
    valor: 0,
    dados: {},
  });

  const steps = ["Revise o seu pedido", "Informações pessoais", "Pagamento"];

  const theme = createTheme({
    palette: {
      primary: {
        main: getConfig(configs, "cor_primaria")
          ? getConfig(configs, "cor_primaria")
          : "#1976d2",
      },
      secondary: {
        main: getConfig(configs, "cor_secundaria")
          ? getConfig(configs, "cor_secundaria")
          : "#125da8",
      },
    },
  });

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <ProdutosForm configs={configs} />;
      case 1:
          return <DadosForm configs={configs} />;
      case 2:
        return <PagamentoForm configs={configs} />;
      default:
        throw new Error("Ops, passo não encontrado.");
    }
  }

  const getConfigs = React.useCallback(() => {
    setLoading(true);
    setErrors([]);

    new ControleService()
      .configs()
      .then((response) => {
        setConfigs(response?.data?.body);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            {
              msg: "não foi possível continuar, tente novamente mais tarde",
            },
          ]
        );
        setLoading(false);
      });
  }, []);

  let interval;
  const redirecionar = () => {
    clearInterval(interval);
    interval = null;

    if (getLogged()) {
      history.goBack();
    } else {
      history.push("/totem");
    }
  };

  const realizarImpressao = async () => {
    setLoading(true);
    clearInterval(interval);

    for await (const pedido of finalizados) {
      const { data } = await new PublicaService().imprimirPedido(
        pedido.id,
        "comprovante",
        colunas
      );
      if (data.body) {
        imprimir(data.body);
      }
    }

    redirecionar();
  };

  const limparInformacoes = () => {
    localStorage.removeItem("totem_cliente_nome");
    localStorage.removeItem("totem_cliente_telefone");
    localStorage.removeItem("totem_cliente_forma_de_pagamento");
    localStorage.removeItem("totem_cliente_forma_de_consumo");
    localStorage.removeItem("totem_cliente_observacao");
    /**
     *
     */
    if (!getLogged()) {
      setModalMaisLojas(true);
    }
  };

  const finalizarPedidos = async () => {
    let pedidos = [];
    let total = {
      valor: 0,
      quantidade: 0,
    };
    /**
     *
     */
    const listar = new CarrinhoService().listar();
    const carrinhos = groupBy(listar, "id");
    /**
     *
     */
    const nome =
      JSON.parse(localStorage.getItem("totem_cliente_nome")) || "Não informado";
    const telefone =
      JSON.parse(localStorage.getItem("totem_cliente_telefone")) ||
      "(00) 0 0000-0000";
    const formaDePagamento =
      JSON.parse(localStorage.getItem("totem_cliente_forma_de_pagamento")) ||
      "";
    const formaDeConsumo =
      JSON.parse(localStorage.getItem("totem_cliente_forma_de_consumo")) || "";
    const observacao =
      JSON.parse(localStorage.getItem("totem_cliente_observacao")) || "";
    /**
     *
     */
    Object.keys(carrinhos).map((loja) => {
      let produtos = [];
      let valor = 0;

      carrinhos[loja].map((carrinho) => {
        produtos.push(carrinho.produto);
        valor += carrinho.produto.preco_total;
        /**
         *
         */
        total.valor += carrinho.produto.preco_total;
        total.quantidade += carrinho.produto.quantidade;

        return carrinho;
      });

      pedidos.push({
        valor: valor,
        observacao: observacao,
        loja: {
          id: loja,
        },
        cliente: {
          nome: `${nome}`,
          telefone: telefone,
        },
        pagamento: {
          nome: formaDePagamento,
          valor_total: valor,
        },
        formaDeConsumo: formaDeConsumo,
        produtos: produtos,
      });

      return loja;
    });
    /**
     *
     */
    setLoading(true);

    new PublicaService()
      .finalizarPedidos(pedidos)
      .then((response) => {
        setLoading(false);

        const { pedidos, pagamento } = response?.data?.body;
        setFinalizados(pedidos);

        new CarrinhoService().limpar();

        setPagamento({
          modal: true,
          id: pagamento.paymentKey,
          formaDePagamento: formaDePagamento,
          valor: total.valor,
          dados: pagamento.methods?.shift().pixInfo,
        });

        switch (formaDePagamento) {
          case "Pix":
            iniciarPix(pagamento.paymentKey);
            break;
          default:
            finalizou();
            break;
        }
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            {
              msg: "não foi possível continuar, tente novamente mais tarde",
            },
          ]
        );
        setLoading(false);
      });
  };

  const iniciarPix = (pix) => {
    let checar = () => {
      new PublicaService()
        .buscarStatusPedido(pix)
        .then((response) => {
          const { descricao } = response?.data?.body;

          switch (descricao) {
            case "pendente":
              console.info("[Tuna.uy] checando pagamento novamente...");
              break;
            case "pago":
              console.info("[Tuna.uy] pix efetuado com sucesso...");
              finalizou();
              break;
            case "negado":
            case "reembolsado":
            case "cancelado":
            default:
              console.info(`[Tuna.uy] pix ${descricao}...`);
              redirecionar();
              break;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    interval = setInterval(checar, 10000);
  };

  const finalizou = () => {
    clearInterval(interval);
    interval = setInterval(() => setTimer((timer) => timer - 1), 1000);
    setPagamento({
      modal: false,
      id: "",
      formaDePagamento: "",
      valor: 0,
      dados: {},
    });
    setActiveStep(activeStep + 1);
    setLoading(false);
    setTimeout(redirecionar, 10000);
  };

  const handleNext = async () => {
    setErrors([]);

    switch (activeStep) {
      case 0:
        const carrinhos = new CarrinhoService().listar();
        if (!carrinhos.length) {
          return;
        }

        setActiveStep(activeStep + 1);
        break;

      case 1:
        const nome =
          JSON.parse(localStorage.getItem("totem_cliente_nome")) || "";
        const telefone =
          JSON.parse(localStorage.getItem("totem_cliente_telefone")) || "";
        /**
         *
         */
        if (!getLogged()) {
          if (!nome || !telefone) {
            return;
          }
        }

        setActiveStep(activeStep + 1);
        break;

      case 2:
        await finalizarPedidos();
        break;

      default:
        throw new Error("Ops, passo não encontrado.");
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      history.goBack();
      return;
    }

    setErrors([]);
    setActiveStep(activeStep - 1);
  };

  React.useEffect(() => {
    limparInformacoes();
    /**
     *
     */
    if (getLogged()) {
      getConfigs();
    }
  }, [getConfigs]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Pix open={pagamento.modal} pagamento={pagamento} />
      <Dialog
        open={modalMaisLojas}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Está satisfeito somente com estes produtos?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Temos diversas outras opções, clique abaixo caso queira visualizar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => redirecionar()}>Ver outras opções</Button>
          <Button onClick={() => setModalMaisLojas(false)}>Continuar</Button>
        </DialogActions>
      </Dialog>
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
        }}>
        <Toolbar>
          <IconButton
            onClick={() => {
              clearInterval(interval);
              history.goBack();
            }}
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            Continuar comprando
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper elevation={0} sx={{ my: { xs: 4, md: 6 }, p: { xs: 3, md: 3 } }}>
          <Typography
            color="primary"
            component="h1"
            variant="h4"
            align="center">
            Resumo do Pedido
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Obrigado pelo seu pedido.
              </Typography>
              <Typography variant="subtitle1">
                Nós iremos confirmar os pedidos em cada um dos estabelecimentos
                e o manteremos informado.
              </Typography>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={redirecionar} sx={{ mt: 3, ml: 1 }}>
                  Fazer um novo pedido
                </Button>

                <Button
                  variant="contained"
                  onClick={realizarImpressao}
                  sx={{ mt: 3, ml: 1 }}>
                  Imprimir comprovante
                </Button>
              </Box>

              <Box sx={{ display: "flex", mt: 3, justifyContent: "center" }}>
                <Typography variant="h5" gutterBottom>
                  {timer}
                </Typography>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              {errors.length ? (
                <Alert severity="error">
                  {errors.map((error) => error.msg).shift()}
                </Alert>
              ) : (
                ""
              )}

              <Grid
                container
                spacing={2}
                sx={{
                  bottom: 0,
                  position: "fixed",
                  width: "100%",
                  mb: 1,
                  backgroundColor: "#fff",
                }}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <Button
                    sx={{
                      width: "200px",
                      height: 50,
                      ml: 1,
                    }}
                    onClick={handleBack}
                    variant="contained"
                    color="secondary">
                    <Typography inline="true" variant="h6" color="white">
                      Voltar
                    </Typography>
                  </Button>
                  {/* )} */}
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      width: "400px",
                      height: 50,
                      ml: 1,
                    }}
                    color="primary">
                    <Typography inline="true" variant="h6" color="white">
                      {activeStep === steps.length - 1
                        ? "Enviar pedido"
                        : "Próximo"}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
