import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Title from "../components/title";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Loading from "../components/loading";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import semImagem from "../../assets/sem_imagem.png";

import ControleService from "../../services/controle";

import { toBase64, validarImagem } from "../../utils/utils";

const theme = createTheme();

export default function Configuracoes() {
  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingAcao, setLoadingAcao] = React.useState(false);
  const [data, setData] = React.useState({
    aberta: 0,
  });

  const parseData = React.useCallback((newData) => {
    let parsedData = {};

    newData.forEach((item) => {
      parsedData[item.campo] = item.valor;
    });

    return parsedData;
  }, []);

  const unparseData = () => {
    let unparseData = [];

    for (let campo of Object.keys(data)) {
      const valor = data[campo];
      unparseData.push({
        campo: campo,
        valor: valor,
      });
    }

    return unparseData;
  };

  const getData = React.useCallback(async () => {
    setErrors([]);
    setLoading(true);

    return new ControleService()
      .configs()
      .then((response) => {
        setLoading(false);
        setData(parseData(response?.data?.body));
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  }, [parseData]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoadingAcao(true);
    setErrors([]);

    return new ControleService()
      .alterarConfigs(unparseData())
      .then((response) => {
        setErrors([]);
        setLoadingAcao(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoadingAcao(false);
      });
  };

  const alterarLogo = async (event) => {
    try {
      setErrors([]);
      const logo = validarImagem(event?.target?.files);
      setData({ ...data, logo: await toBase64(data.logo, logo) });
    } catch (err) {
      setErrors([
        {
          msg: err.message,
        },
      ]);
      return false;
    }
  };

  const alterarCapa = async (event) => {
    try {
      setErrors([]);
      const capa = validarImagem(event?.target?.files);
      setData({ ...data, capa: await toBase64(data.capa, capa) });
    } catch (err) {
      setErrors([
        {
          msg: err.message,
        },
      ]);
      return false;
    }
  };

  React.useEffect(() => {
    getData();
  }, [getData]);

  return (
    <ThemeProvider theme={theme}>
      <Loading loading={loading} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <Title>Configurações</Title>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel>Situação da loja</InputLabel>
                      <Select
                        id="aberta"
                        name="aberta"
                        onChange={handleChange}
                        value={data.aberta}
                        autoWidth
                        label="Situação da loja"
                        required>
                        <MenuItem value="1">Aberta</MenuItem>
                        <MenuItem value="0">Fechada</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="regulamento"
                      name="regulamento"
                      label="Regulamento"
                      type="text"
                      variant="outlined"
                      autoComplete="regulamento"
                      value={data.regulamento}
                      onChange={handleChange}
                      multiline
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <Autocomplete
                      multiple={true}
                      id="formasDePagamento"
                      name="formasDePagamento"
                      value={[...(data.formasDePagamento || [])]}
                      onChange={(event, newValue) => {
                        setData({ ...data, formasDePagamento: newValue });
                      }}
                      options={[
                        "Pix",
                        "Dinheiro",
                        "Cartão de Débito",
                        "Cartão de Crédito",
                        "Vale Alimentação",
                        "Vale Refeição",
                        "Outra",
                      ]}
                      getOptionDisabled={(option) => option === "Pix"}
                      renderInput={(params) => (
                        <TextField {...params} label="Formas de Pagamento" />
                      )}
                      disableCloseOnSelect
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="pedido_minimo"
                      name="pedido_minimo"
                      label="Valor mínimo por pedido (R$)"
                      type="number"
                      variant="outlined"
                      autoComplete="pedido_minimo"
                      value={data.pedido_minimo}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="tempo_medio_entrega"
                      name="tempo_medio_entrega"
                      label="Tempo média de entrega (min)"
                      type="text"
                      variant="outlined"
                      autoComplete="tempo_medio_entrega"
                      value={data.tempo_medio_entrega}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="cor_primaria"
                      name="cor_primaria"
                      label="Cor primária"
                      type="color"
                      variant="outlined"
                      autoComplete="cor_primaria"
                      value={data.cor_primaria}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <TextField
                      id="cor_secundaria"
                      name="cor_secundaria"
                      label="Cor secundária"
                      type="color"
                      variant="outlined"
                      autoComplete="cor_secundaria"
                      value={data.cor_secundaria}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <Typography color="text.primary">Capa</Typography>
                    <img
                      src={data.capa || semImagem}
                      width="120px"
                      height="120px"
                      alt="capa"
                      title="capa"
                    />
                    <input name="capa" type="file" onChange={alterarCapa} />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <Typography color="text.primary">Logo</Typography>
                    <img
                      src={data.logo || semImagem}
                      width="120px"
                      height="120px"
                      alt="logo"
                      title="logo"
                    />
                    <input name="logo" type="file" onChange={alterarLogo} />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2 }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel>Teclado inteligente</InputLabel>
                      <Select
                        id="teclado"
                        name="teclado"
                        onChange={handleChange}
                        value={data.teclado}
                        autoWidth
                        label="Habilitar teclado inteligente"
                        required>
                        <MenuItem value="1">Sim</MenuItem>
                        <MenuItem value="0">Não</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ m: 2, mt: 3 }}>
                    <TextField
                      id="webhookUrl"
                      name="webhookUrl"
                      label="Webhook Url"
                      type="url"
                      variant="outlined"
                      autoComplete="webhookUrl"
                      value={data.webhookUrl}
                      onChange={handleChange}
                      multiline
                      fullWidth
                    />
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px",
                    }}>
                    <LoadingButton
                      type="submit"
                      loading={loadingAcao}
                      variant="contained">
                      Alterar
                    </LoadingButton>
                  </div>
                  {errors.length ? (
                    <Alert severity="error">
                      {errors.map((error) => error.msg).shift()}
                    </Alert>
                  ) : (
                    ""
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
