import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputMask from "react-input-mask";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { useLocalStorage } from "../../../utils/useLocalStorage";
import { tecladoNumerico, tecladoPadrao } from "../../../utils/teclado";
import { getLogged, getConfig, isActive } from "../../../utils/utils";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default function DadosForm(props) {
  const [nome, setNome] = useLocalStorage("totem_cliente_nome", "");
  const [telefone, setTelefone] = useLocalStorage("totem_cliente_telefone", "");
  const [tecladoLayoutNome, setTecladoLayoutNome] = React.useState("shift");
  const [tecladoLayout, setTecladoLayout] = React.useState({});
  const [teclado, setTeclado] = React.useState("");
  const [informar, setInformar] = React.useState("");

  const theme = useTheme();

  const onChange = (input) => {
    setTecladoLayoutNome("default");

    switch (teclado) {
      case "nome":
        setNome(input);
        break;
      case "telefone":
        setTelefone(input);
        break;
      default:
        setTeclado("");
        break;
    }
  };

  const onKeyPress = (button) => {
    if (button === "{shift}") {
      handleShift();
    }
  };

  const handleShift = () => {
    setTecladoLayoutNome(tecladoLayoutNome === "default" ? "shift" : "default");
  };

  const alterarCampoTeclado = (campo) => {
    if (!isActive(getConfig(props.configs, "teclado"))) {
      console.error("O teclado inteligente foi desativado.");
      return;
    }
    /**
     *
     */
    setTeclado("");
    /**
     *
     */
    switch (campo) {
      case "telefone":
        setTecladoLayout(tecladoNumerico);
        setTecladoLayoutNome("default");
        break;
      default:
        setTecladoLayout(tecladoPadrao);
        setTecladoLayoutNome("shift");
        break;
    }
    /**
     *
     */
    setTimeout(() => {
      setTeclado(campo);
    }, 200);
  };

  function getIcon(item) {
    switch (item) {
      case "Sim":
        return <CheckCircleOutlineIcon />;
      case "Não":
        return <CancelIcon />;
      default:
        return <CheckCircleOutlineIcon />;
    }
  }

  function RadioOpcoesInformar({ items }) {
    return (
      <Grid
        container
        spacing={2}
        style={{
          margin: 15,
        }}>
        {items.map((item) => (
          <Card
            sx={{ display: "flex" }}
            style={{
              backgroundColor:
                informar !== item
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              color: "#FFF",
              width: 250,
              height: 100,
              margin: 5,
            }}
            onClick={() => setInformar(item)}
            key={item}>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
              }}>
              <Typography
                component="div"
                variant="h6"
                sx={{
                  margin: 1,
                  marginTop: 2,
                }}>
                {getIcon(item)}
              </Typography>
              <Typography component="div" variant="h6">
                {item}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Informações pessoais
      </Typography>
      <Grid container spacing={3}>
        {getLogged() ? (
          <Grid item xs={12} sm={12}>
            <FormControl>
              <FormControl>
                <FormLabel id="informar-dados-label">
                  Deseja receber informações do seu pedido pelo WhatsApp?
                </FormLabel>
                {getLogged() ? (
                  <RadioOpcoesInformar items={getConfig(props.configs, "whatsapp_token") && getConfig(props.configs, "whatsapp_instancia") ? ["Sim", "Não"] : ["Não"]} />
                ) : (
                  ""
                )}
              </FormControl>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}

        {!getLogged() || informar === "Sim" ? (
          <Grid item xs={12} sm={6}>
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              autoComplete="given-name"
              variant="standard"
              value={nome}
              onChange={(event) => {
                setNome(event.target.value);
              }}
              onClick={(event) => {
                alterarCampoTeclado(event.target.name);
              }}
              required={getLogged() ? false : true}
              fullWidth
            />
          </Grid>
        ) : (
          ""
        )}

        {!getLogged() || informar === "Sim" ? (
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="(99) 9 9999-9999"
              maskChar=""
              value={telefone}
              onChange={(event) => {
                setTelefone(event.target.value);
              }}>
              {(inputProps) => (
                <TextField
                  id="telefone"
                  name="telefone"
                  label="Telefone"
                  type="text"
                  variant="standard"
                  autoComplete="phone"
                  inputProps={{ inputMode: "numeric" }}
                  onClick={(event) => {
                    alterarCampoTeclado(event.target.name);
                  }}
                  required={getLogged() ? false : true}
                  fullWidth
                  {...inputProps}
                />
              )}
            </InputMask>
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      {!getLogged() || informar === "Sim" ? (
        <Box sx={{ mt: 5 }}>
          {teclado ? (
            <Keyboard
              layoutName={tecladoLayoutNome}
              layout={tecladoLayout}
              inputName={teclado}
              onChange={onChange}
              onKeyPress={onKeyPress}
              disableButtonHold={true}
            />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
