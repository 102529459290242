import * as React from "react";
import Typography from "@mui/material/Typography";
import Title from "../components/title";

export default function TotalizadorHoje(props) {
  return (
    <React.Fragment>
      <Title>Hoje</Title>
      <Typography component="p" variant="h4">
        {parseFloat(props.totalizador.valor).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {new Date(props.totalizador.data).toLocaleString("pt-BR", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </Typography>
    </React.Fragment>
  );
}
