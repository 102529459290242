export const GRID_DEFAULT_LOCALE_TEXT = {
  noRowsLabel: "Nenhum resultado encontrado.",
  noResultsOverlayLabel: "Nenhum resultado encontrado.",

  toolbarDensity: "Densidade",
  toolbarDensityLabel: "Densidade",
  toolbarDensityCompact: "Compacto",
  toolbarDensityStandard: "Padrão",
  toolbarDensityComfortable: "Confortável",

  toolbarColumns: "Colunas",
  toolbarColumnsLabel: "Selecionar colunas",

  toolbarFilters: "Filtros",
  toolbarFiltersLabel: "Mostrar filtros",
  toolbarFiltersTooltipHide: "Ocultar filtros",
  toolbarFiltersTooltipShow: "Mostrar filtros",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

  toolbarQuickFilterPlaceholder: "Pesquisar...",
  toolbarQuickFilterLabel: "Pesquisar",
  toolbarQuickFilterDeleteIconLabel: "Limpar",

  toolbarExport: "Exportar",
  toolbarExportLabel: "Exportar",
  toolbarExportCSV: "Baixar como CSV",
  toolbarExportPrint: "Imprimir",
  toolbarExportExcel: "Baixar como Excel",

  columnsPanelTextFieldLabel: "Localizar coluna",
  columnsPanelTextFieldPlaceholder: "Título da coluna",
  columnsPanelDragIconLabel: "Reordenar coluna",
  columnsPanelShowAllButton: "Mostrar tudo",
  columnsPanelHideAllButton: "Ocultar tudo",

  filterPanelAddFilter: "Adicionar filtro",
  filterPanelRemoveAll: "Remover tudo",
  filterPanelDeleteIconLabel: "Excluir",
  filterPanelLogicOperator: "Operador lógico",
  filterPanelOperator: "Operador",
  filterPanelOperatorAnd: "E",
  filterPanelOperatorOr: "Ou",
  filterPanelColumns: "Colunas",
  filterPanelInputLabel: "Valor",
  filterPanelInputPlaceholder: "Valor do filtro",

  filterOperatorContains: "contém",
  filterOperatorEquals: "igual",
  filterOperatorStartsWith: "começa com",
  filterOperatorEndsWith: "termina com",
  filterOperatorIs: "é",
  filterOperatorNot: "não é",
  filterOperatorAfter: "é depois",
  filterOperatorOnOrAfter: "está ligado ou depois",
  filterOperatorBefore: "é antes",
  filterOperatorOnOrBefore: "está ligado ou antes",
  filterOperatorIsEmpty: "está vazio",
  filterOperatorIsNotEmpty: "não está vazio",
  filterOperatorIsAnyOf: "é qualquer um",

  filterValueAny: "qualquer",
  filterValueTrue: "verdadeiro",
  filterValueFalse: "false",

  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Mostrar colunas",
  columnMenuManageColumns: "Gerenciar colunas",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Ocultar coluna",
  columnMenuUnsort: "Unsort",
  columnMenuSortAsc: "Ordenar por ASC",
  columnMenuSortDesc: "Classificar por DESC",

  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
  columnHeaderFiltersLabel: "Mostrar filtros",
  columnHeaderSortIconLabel: "Classificar",

  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  footerTotalRows: "Total de linhas:",

  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  checkboxSelectionHeaderName: "Seleção da caixa de seleção",
  checkboxSelectionSelectAllRows: "Selecionar todas as linhas",
  checkboxSelectionUnselectAllRows: "Desmarcar todas as linhas",
  checkboxSelectionSelectRow: "Selecionar linha",
  checkboxSeleçãoDesselecionarLinha: "Desmarcar linha",

  booleanCellTrueLabel: "sim",
  booleanCellFalseLabel: "não",

  actionsCellMore: "mais",

  pinToLeft: "Fixar à esquerda",
  pinToRight: "Fixar à direita",
  unpin: "Unpin",

  treeDataGroupingHeaderName: "Grupo",
  treeDataExpand: "ver filhos",
  treeDataCollapse: "ocultar filhos",

  groupingColumnHeaderName: "Grupo",
  groupColumn: (nome) => `Agrupar por ${nome}`,
  unGroupColumn: (nome) => `Parar de agrupar por ${nome}`,

  detailPanelToggle: "Alternância do painel de detalhes",
  expandDetailPanel: "Expandir",
  collapseDetailPanel: "Recolher",

  MuiTablePagination: {
    labelRowsPerPage: "Registros por página",
  },

  rowReorderingHeaderName: "Reordenação de linha",

  aggregationMenuItemHeader: "Agregação",
  aggregationFunctionLabelSum: "soma",
  aggregationFunctionLabelAvg: "avg",
  aggregationFunctionLabelMin: "min",
  aggregationFunctionLabelMax: "max",
  agregationFunctionLabelSize: "tamanho",
};
