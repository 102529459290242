import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

import Message from "../components/message";

import { useLocalStorage } from "../../utils/useLocalStorage";

import AutenticacaoService from "../../services/autenticacao";

import { useHistory } from "react-router-dom";

const theme = createTheme();

export default function Entrar() {
  let history = useHistory();

  const [email, setEmail] = useLocalStorage("email", "");
  const [loading, setLoading] = React.useState(false);
  const [loadingRecuperacao, setLoadingRecuperacao] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [message, setMessage] = React.useState("");

  const handleSubmit = (event) => {
    setLoading(true);
    setErrors([]);
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    new AutenticacaoService()
      .login({
        email: data.get("email"),
        senha: data.get("senha"),
      })
      .then((response) => {
        localStorage.setItem("jwt", response?.data?.body?.jwt);
        history.push("/dashboard");
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  const recuperarSenha = async () => {
    setLoadingRecuperacao(true);
    setErrors([]);

    new AutenticacaoService()
      .recuperarSenha({
        email: email,
      })
      .then((response) => {
        setMessage(
          "A sua nova senha foi enviado para o seu telefone, não esqueça de alterar."
        );
        setLoadingRecuperacao(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoadingRecuperacao(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Message text={message} />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Informe suas credenciais
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="E-mail"
              type="email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="senha"
              label="Senha"
              type="password"
              id="senha"
              autoComplete="current-password"
            />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              loading={loading}>
              Entrar
            </LoadingButton>
            <Button
              onClick={() => history.push("/totem")}
              sx={{ mt: 1, mb: 1 }}
              variant="outlined"
              fullWidth>
              Acessar totem
            </Button>
            <LoadingButton
              onClick={() => recuperarSenha()}
              sx={{ mt: 1, mb: 1 }}
              loading={loadingRecuperacao}
              fullWidth>
              Esqueceu sua senha?
            </LoadingButton>
          </Box>
        </Box>

        {errors.length ? (
          <Alert severity="error">
            {errors.map((error) => error.msg).shift()}
          </Alert>
        ) : (
          ""
        )}
      </Container>
    </ThemeProvider>
  );
}
