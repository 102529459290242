import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StoreIcon from "@mui/icons-material/Store";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import TuneIcon from "@mui/icons-material/Tune";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import AccountBalanceIcon from "@mui/icons-material/Payment";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import CategoryIcon from "@mui/icons-material/Category";
import StyleIcon from "@mui/icons-material/Style";
// import CactusIcon from "@mui/icons-material/Cactus";
import { Link } from "react-router-dom";

import { API_URL } from "../../constants/config";

export const MenuControle = () => {
  const [collapseCardapio, setCollapseCardapio] = React.useState(false);
  const [collapseAjustes, setCollapseAjustes] = React.useState(false);

  return (
    <React.Fragment>
      <ListItemButton component={Link} to="/dashboard">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton component={Link} to="/dashboard/pedidos">
        <ListItemIcon>
          <ReceiptLongIcon />
        </ListItemIcon>
        <ListItemText primary="Pedidos" />
      </ListItemButton>

      <ListItemButton onClick={() => setCollapseCardapio(!collapseCardapio)}>
        <ListItemIcon>
          <RestaurantMenuIcon />
        </ListItemIcon>
        <ListItemText primary="Cardápio" />
        {collapseCardapio ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={collapseCardapio} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/categorias">
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText primary="Categorias" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/produtos">
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Produtos" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/grupo-opcoes">
            <ListItemIcon>
              <StyleIcon />
            </ListItemIcon>
            <ListItemText primary="Grupo de Opções" />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton onClick={() => setCollapseAjustes(!collapseAjustes)}>
        <ListItemIcon>
          <ToggleOnIcon />
        </ListItemIcon>
        <ListItemText primary="Ajustes" />
        {collapseAjustes ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={collapseAjustes} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/configuracoes/pagamentos"
            disabled>
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Pagamentos" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/configuracoes/whatsapp">
            <ListItemIcon>
              <WhatsAppIcon />
            </ListItemIcon>
            <ListItemText primary="WhatsApp" />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/dashboard/configuracoes">
            <ListItemIcon>
              <TuneIcon />
            </ListItemIcon>
            <ListItemText primary="Configurações" />
          </ListItemButton>
        </List>
      </Collapse>

      <Divider sx={{ my: 1 }} />
    </React.Fragment>
  );
};

export const menuGestao = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Administração
    </ListSubheader>
    <ListItemButton component={Link} to="/dashboard/lojas">
      <ListItemIcon>
        <StoreIcon />
      </ListItemIcon>
      <ListItemText primary="Lojas" />
    </ListItemButton>
    <ListItemButton component={Link} to="/dashboard/usuarios">
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Usuários" />
    </ListItemButton>
    <Divider sx={{ my: 1 }} />
  </React.Fragment>
);

export const menuAvancado = (props) => (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Avançado
    </ListSubheader>
    <ListItemButton href={`/totem/loja/${props?.loja?.id}`} target="_blank">
      <ListItemIcon>
        <ScreenshotMonitorIcon />
      </ListItemIcon>
      <ListItemText primary="Totem" />
    </ListItemButton>
    <ListItemButton
      href={`https://console.tuna.uy/gama-delivery/gama-totem/`}
      target="_blank">
      <ListItemIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M14 16v5h-4v-3H9a3 3 0 0 1-3-3v-3a1 1 0 0 1 1-1a1 1 0 0 1 1 1v3c0 .56.45 1 1 1h1V6a2 2 0 0 1 2-2a2 2 0 0 1 2 2v8h1a1 1 0 0 0 1-1v-2a1 1 0 0 1 1-1a1 1 0 0 1 1 1v2a3 3 0 0 1-3 3h-1Z"
          />
        </svg>
      </ListItemIcon>
      <ListItemText primary="Tuna.uy" />
    </ListItemButton>
    <ListItemButton href={`${API_URL}/doc`} target="_blank">
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Documentação" />
    </ListItemButton>
    <Divider sx={{ my: 1 }} />
  </React.Fragment>
);
