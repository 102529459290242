import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Message from "../../components/message";

import PublicaService from "../../../services/publica";

export default function Pix(props) {
  const [message, setMessage] = React.useState("");
  const [currentTime, setCurrentTime] = React.useState({
    minutes: 5,
    seconds: 0,
  });

  const cancelar = (pix) => {
    setMessage("Aguarde, estamos cancelando o pagamento...");

    return new PublicaService()
      .cancelarPagamento(pix)
      .then((response) => {
        console.info(`[Tuna.uy] pix sendo cancelado...`, response?.data?.body);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (props.open) {
        setCurrentTime((currentTime) => {
          if (currentTime.seconds === 0) {
            if (currentTime.minutes === 0) {
              return {
                ...currentTime,
                minutes: 0,
                seconds: 0,
              };
            }
            return {
              ...currentTime,
              minutes: currentTime.minutes - 1,
              seconds: 59,
            };
          } else {
            return {
              ...currentTime,
              minutes: currentTime.minutes,
              seconds: currentTime.seconds - 1,
            };
          }
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [props.open]);

  return (
    <div>
      <Message text={message} />
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Pix"}</DialogTitle>
        <DialogContent>
          <Typography color="gray" align="center">
            Abra o aplicativo do seu banco, escolha a opção Pagar via PIX, em
            seguida Ler QR Code.
          </Typography>

          <div
            style={{
              textAlign: "center",
              marginTop: 15,
            }}>
            <img
              src={props.pagamento?.dados?.qrImage}
              width="180px"
              height="180px"
              alt="Qr Code"
            />
          </div>

          <Typography align="center">
            Valor total a pagar:{" "}
            {parseFloat(props.pagamento?.valor).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}{" "}
            (Tuna pagamentos)
          </Typography>

          <Typography
            variant="h5"
            color="primary"
            align="center"
            sx={{ mt: 1 }}>
            {String(currentTime.minutes).padStart(2, 0)}:
            {String(currentTime.seconds).padStart(2, 0)}
          </Typography>

          <Typography
            color="gray"
            align="center"
            sx={{
              mt: 2,
            }}>
            Após o pagamento, iremos enviar enviar o seu pedido para os
            estabelecimentos em segundos.
          </Typography>

          <Typography align="center">
            <Button
              sx={{
                mt: 2,
              }}
              size="small"
              color="primary"
              align="center"
              variant="outlined"
              onClick={() => cancelar(props.pagamento?.id)}>
              Desistir, não efetuarei o pagamento
            </Button>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
