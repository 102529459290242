import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Title from "../components/title";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { Link, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Tooltip from "@mui/material/Tooltip";

import { GRID_DEFAULT_LOCALE_TEXT } from "../../constants/dataGrid";
import ControleService from "../../services/controle";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
  },
});

export default function Opcoes() {
  let { idGrupoOpcoes } = useParams();

  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [grupo, setGrupo] = React.useState({
    id: "",
    nome: "",
  });

  const columns = [
    { field: "nome", headerName: "Nome", width: 250 },
    { field: "descricao", headerName: "Descrição", width: 250 },
    {
      field: "preco",
      headerName: "Preço",
      width: 200,
      valueGetter: (params) =>
        parseFloat(params.row.preco).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    { field: "ativo", headerName: "Ativo", type: "boolean", width: 250 },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Remover">
              <DeleteIcon />
            </Tooltip>
          }
          label="Remover"
          onClick={() => remover(params.row.id)}
        />,
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label="Alterar"
          component={Link}
          to={`/dashboard/opcoes/${idGrupoOpcoes}/alterar/${params.row.id}`}
          showInMenu
        />,
        <GridActionsCellItem
          icon={params.row.ativo ? <DoDisturbOffIcon /> : <DoDisturbOnIcon />}
          label={params.row.ativo ? "Desativar" : "Ativar"}
          onClick={() => alterar(params.row.id, params.row)}
          showInMenu
        />,
      ],
    },
  ];

  const getData = React.useCallback(async () => {
    if (!idGrupoOpcoes) {
      return;
    }

    setErrors([]);

    new ControleService()
      .grupoOpcao(idGrupoOpcoes)
      .then((response) => {
        setGrupo(response?.data?.body);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
      });
  }, [idGrupoOpcoes]);

  const getRows = React.useCallback(() => {
    setErrors([]);

    new ControleService()
      .opcoes(idGrupoOpcoes)
      .then((response) => {
        setRows(response?.data?.body);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  }, [idGrupoOpcoes]);

  const alterar = (id, dados) => {
    setErrors([]);
    setLoading(true);

    dados.grupo_opcoes = dados.grupo_opcoes.id;
    dados.ativo = !dados.ativo;

    new ControleService()
      .alterarOpcao(id, dados)
      .then((response) => {
        getRows();
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  const remover = (id) => {
    setErrors([]);
    setLoading(true);

    new ControleService()
      .deletarOpcao(id)
      .then((response) => {
        getRows();
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  const getPromisse = React.useCallback(() => {
    return Promise.all([getData(), getRows()]);
  }, [getData, getRows]);

  React.useEffect(() => {
    getPromisse();
  }, [getPromisse]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {errors.length ? (
                  <Alert severity="error">
                    {errors.map((error) => error.msg).shift()}
                  </Alert>
                ) : (
                  ""
                )}
                <Title>
                  <IconButton
                    aria-label="voltar"
                    component={Link}
                    to={"/dashboard/grupo-opcoes"}>
                    <ArrowBackIcon />
                  </IconButton>
                  {grupo.nome}
                  <IconButton
                    aria-label="adicionar"
                    component={Link}
                    to={`/dashboard/opcoes/${idGrupoOpcoes}/alterar`}>
                    <AddIcon />
                  </IconButton>
                </Title>
                <div style={{ height: 640, width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    disableRowSelectionOnClick={true}
                    disableDensitySelector={true}
                    disableColumnSelector={true}
                    rowSelection={false}
                    localeText={GRID_DEFAULT_LOCALE_TEXT}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                    }}
                    pageSizeOptions={[10, 50, 100]}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
