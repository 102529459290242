import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Title from "../components/title";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

import NotificacaoAudio from "../../assets/audios/notificacao.mp3";

import { GRID_DEFAULT_LOCALE_TEXT } from "../../constants/dataGrid";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { imprimir } from "../../utils/utils";

import ControleService from "../../services/controle";
import GestaoService from "../../services/gestao";
import PublicaService from "../../services/publica";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
  },
});

export default function Pedidos(props) {
  const audioPlayer = React.useRef(null);

  const [errors, setErrors] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState([]);
  const [colunas] = useLocalStorage("config_imp_colunas", "60");

  const getNovoStatus = (atual) => {
    switch (atual.toLowerCase()) {
      case "aguardando pagamento":
      case "recebido":
      case "finalizado":
      case "cancelado":
        return "Em Preparo";
      case "em preparo":
        return "Pronto para retirada";
      case "pronto para retirada":
        return "Finalizado";
      default:
        return "Finalizado";
    }
  };

  const columns = [
    { field: "numero", headerName: "Número", width: 100 },
    {
      field: "cliente",
      headerName: "Cliente",
      width: 180,
      valueGetter: (params) => params.row.cliente.nome,
    },
    {
      field: "pagamento",
      headerName: "Forma de Pagamento",
      width: 200,
      valueGetter: (params) => params.row.pagamento.nome,
    },
    {
      field: "valor_total",
      headerName: "Total",
      width: 100,
      valueGetter: (params) =>
        parseFloat(params.row.pagamento.valor_total).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      field: "data_recebimento",
      headerName: "Data",
      width: 150,
      valueGetter: (params) =>
        new Date(params.row.data_recebimento).toLocaleString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
    },
    {
      field: "status",
      headerName: "Status",
      width: 220,
      renderCell: (params) => {
        let cor;
        let icon;

        switch (params.value) {
          case "finalizado":
            cor = "success";
            icon = <CheckIcon />;
            break;
          case "cancelado":
            cor = "error";
            icon = <CancelIcon />;
            break;
          default:
            cor = "warning";
            icon = <HourglassBottomIcon />;
            break;
        }

        return (
          <Chip
            icon={icon}
            label={params.value}
            variant={"outlined"}
            color={cor}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 5,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FileCopyIcon />}
          label={getNovoStatus(params.row.status)}
          onClick={() =>
            alterar(params.row.id, getNovoStatus(params.row.status))
          }
          showInMenu
          disabled={
            ["aguardando pagamento", "finalizado", "cancelado"].includes(
              params.row.status
            )
              ? true
              : false
          }
        />,
        <GridActionsCellItem
          icon={<DoDisturbOffIcon />}
          label="Cancelar"
          onClick={() => alterar(params.row.id, "cancelado")}
          showInMenu
          disabled={
            ["finalizado", "cancelado"].includes(params.row.status)
              ? true
              : false
          }
        />,
      ],
    },
    {
      field: "actions2",
      headerName: "",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            <Tooltip title="Imprimir comanda">
              <ReceiptIcon />
            </Tooltip>
          }
          label="Imprimir comanda"
          onClick={() => impressao(params.row.id, "comanda")}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Imprimir comprovante">
              <ReceiptLongIcon />
            </Tooltip>
          }
          label="Imprimir comprovante"
          onClick={() => impressao(params.row.id, "comprovante")}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title="Remover">
              <DeleteIcon />
            </Tooltip>
          }
          label="Remover"
          onClick={() => remover(params.row.id)}
          disabled={props.nivel === 0 ? true : false}
        />,
      ],
    },
  ];

  const playNotificacao = () => {
    audioPlayer.current.play();
  };

  const pauseNotificacao = () => {
    audioPlayer.current.pause();
  };

  const alterar = (id, status) => {
    setErrors([]);
    setLoading(true);

    status = status.toLowerCase();

    switch (status) {
      case "em preparo":
        impressao(id, "comprovante");
        break;
      default:
        break;
    }

    new ControleService()
      .alterarPedido(id, { status: status })
      .then((response) => {
        getRows();
        pauseNotificacao();
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  const getRows = React.useCallback(() => {
    setErrors([]);

    new ControleService()
      .pedidos()
      .then((response) => {
        const pedidos = response?.data?.body;

        pedidos.forEach((pedido) => {
          switch (pedido.status) {
            case "recebido":
              playNotificacao();
              break;
            default:
              break;
          }
        });

        setRows(pedidos);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  }, []);

  const remover = (id) => {
    setErrors([]);
    setLoading(true);

    new GestaoService()
      .deletarPedido(id)
      .then((response) => {
        getRows();
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  const impressao = (id, tipo) => {
    setErrors([]);
    setLoading(true);

    new PublicaService()
      .imprimirPedido(id, tipo, colunas)
      .then((response) => {
        imprimir(response?.data?.body);
        setLoading(false);
      })
      .catch((err) => {
        setErrors(
          err?.response?.data?.errors || [
            { msg: "não foi possível continuar, tente novamente mais tarde" },
          ]
        );
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getRows();
    const interval = setInterval(getRows, 60000);

    return () => clearInterval(interval);
  }, [getRows]);

  return (
    <ThemeProvider theme={theme}>
      <audio ref={audioPlayer} src={NotificacaoAudio} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                {errors.length ? (
                  <Alert severity="error">
                    {errors.map((error) => error.msg).shift()}
                  </Alert>
                ) : (
                  ""
                )}
                <Title>Pedidos</Title>
                <div style={{ height: 640, width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    disableRowSelectionOnClick={true}
                    disableDensitySelector={true}
                    disableColumnSelector={true}
                    rowSelection={false}
                    localeText={GRID_DEFAULT_LOCALE_TEXT}
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 10, page: 0 },
                      },
                    }}
                    pageSizeOptions={[10, 50, 100]}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
