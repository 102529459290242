import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

import semImagem from "../../../assets/sem_imagem.png";

import CarrinhoService from "../../../services/carrinho";

import { tecladoPadrao } from "../../../utils/teclado";
import { getConfig, isActive } from "../../../utils/utils";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

export default function Produto(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: props.cor_primaria ? props.cor_primaria : "#1976d2",
      },
      secondary: {
        main: props.cor_secundaria ? props.cor_secundaria : "#125da8",
      },
    },
  });

  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [observacao, setObservacao] = React.useState("");
  const [tecladoLayoutNome, setTecladoLayoutNome] = React.useState("shift");
  const [tecladoLayout, setTecladoLayout] = React.useState({});
  const [teclado, setTeclado] = React.useState("");
  const [grupoOpcoes, setGrupoOpcoes] = React.useState([]);
  const [quantidade, setQuantidade] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  const onChange = (input) => {
    setTecladoLayoutNome("default");

    switch (teclado) {
      case "observacao":
        setObservacao(input);
        break;
      default:
        setTeclado("");
        break;
    }
  };

  const onKeyPress = (button) => {
    if (button === "{shift}") {
      handleShift();
    }
  };

  const handleShift = () => {
    setTecladoLayoutNome(tecladoLayoutNome === "default" ? "shift" : "default");
  };

  const alterarCampoTeclado = (campo) => {
    if (!isActive(getConfig(props.configs, "teclado"))) {
      console.error("O teclado inteligente foi desativado.");
      return;
    }
    /**
     *
     */
    setTeclado("");
    setTecladoLayout(tecladoPadrao);
    setTecladoLayoutNome("default");
    /**
     *
     */
    setTimeout(() => {
      setTeclado(campo);
    }, 200);
  };

  const getGrupoOpcoes = React.useCallback(
    (byPass) => {
      let retorno = {
        errors: [],
        grupos: [],
        totalizador: 0,
      };

      if (props.grupo_opcoes) {
        props.grupo_opcoes
          .filter((grupo) => grupo.ativo)
          .filter(
            (grupo) => grupo.opcoes.filter((opcao) => opcao.ativo).length > 0
          )
          .map((grupo) => {
            const quantidadeGrupo = grupoOpcoes.filter(
              (grupo_opcao) => grupo_opcao.id === grupo.id
            ).length;

            if (grupo.obrigatorio && quantidadeGrupo <= 0) {
              retorno.errors.push({
                msg: `É obrigatório o preenchimento do "${grupo.nome}"`,
              });
              return grupo;
            }

            if (grupo.min > 0 && quantidadeGrupo < grupo.min) {
              retorno.errors.push({
                msg: `O "${grupo.nome}" precisa de no mínimo ${grupo.min} opçõe(s)`,
              });
              return grupo;
            }

            if (grupo.max > 0 && quantidadeGrupo > grupo.max) {
              retorno.errors.push({
                msg: `O "${grupo.nome}" deve atingir no máximo ${grupo.max} opçõe(s)`,
              });
              return grupo;
            }

            grupo.opcoes
              .filter((opcao) => opcao.ativo)
              .map((opcao) => {
                const selecionado = grupoOpcoes.filter(
                  (grupo_opcao) => grupo_opcao.opcao === opcao.id
                ).length;
                if (selecionado) {
                  retorno.grupos.push({
                    grupo_opcoes: grupo.id,
                    opcao: opcao.id,
                    nome: opcao.nome,
                    preco_unitario: parseFloat(opcao.preco),
                    preco_custo: parseFloat(opcao.preco_custo),
                    preco_total: quantidadeGrupo * parseFloat(opcao.preco),
                    quantidade: quantidadeGrupo,
                  });
                  retorno.totalizador +=
                    quantidadeGrupo * parseFloat(opcao.preco);
                }

                return opcao;
              });

            return grupo;
          });
      }

      if (byPass) {
        retorno.errors = [];
      }

      return retorno;
    },
    [grupoOpcoes, props.grupo_opcoes]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors([]);

    const data = new FormData(event.currentTarget);
    const opcoes = getGrupoOpcoes();

    if (opcoes.errors.length > 0) {
      setErrors(opcoes.errors);
      return;
    }

    const adicionar = new CarrinhoService().adicionar(props.loja, {
      produto: props.id,
      nome: props.nome,
      preco_unitario: parseFloat(props.preco),
      preco_unitario_opcoes: opcoes.totalizador,
      preco_custo: parseFloat(props.preco_custo),
      preco_total: quantidade * (parseFloat(props.preco) + opcoes.totalizador),
      observacao: data.get("observacao"),
      quantidade: quantidade,
      opcoes: opcoes.grupos,
    });
    if (!adicionar) {
      setErrors([
        {
          msg: "Não foi possível adicionar o produto no carrinho.",
        },
      ]);
      return;
    }

    props.callback();
    setTimeout(() => setOpen(false));
  };

  const addOpcao = (grupo_opcao, opcao) => {
    const { max } = props.grupo_opcoes
      .filter((grupo) => grupo.id === grupo_opcao)
      .shift();
    const quantidadeGrupo = grupoOpcoes.filter(
      (grupo) => grupo.id === grupo_opcao
    ).length;

    if (max > 0 && quantidadeGrupo + 1 > max) {
      return;
    }

    if (max <= 0 && quantidadeGrupo + 1 > 10) {
      return;
    }

    setGrupoOpcoes([
      ...grupoOpcoes,
      {
        id: grupo_opcao,
        opcao: opcao,
      },
    ]);
  };

  const removerOpcao = (grupo_opcao, opcao) => {
    let removido = false;
    setGrupoOpcoes((opcs) =>
      opcs.filter((grupo) => {
        if (!removido) {
          if (grupo.id === grupo_opcao && grupo.opcao === opcao) {
            removido = true;
            return false;
          }
        }

        return true;
      })
    );
  };

  const atualizarTotal = React.useCallback(() => {
    const opcoes = getGrupoOpcoes(true);

    if (opcoes.errors.length > 0) {
      setErrors(opcoes.errors);
      return;
    }

    setTotal((parseFloat(props.preco) + opcoes.totalizador) * quantidade);
  }, [getGrupoOpcoes, props.preco, quantidade]);

  const alterarQuantidade = (acao) => {
    switch (acao) {
      case "adicionar":
        if (quantidade + 1 <= 10) {
          setQuantidade(quantidade + 1);
        }
        break;

      case "remover":
        if (quantidade - 1 >= 1) {
          setQuantidade(quantidade - 1);
        }
        break;

      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    atualizarTotal();
  }, [quantidade, grupoOpcoes, atualizarTotal]);

  React.useEffect(() => {
    if (props.open) {
      setOpen(true);
    }

    setTotal(props.preco);
    setQuantidade(1);
    setGrupoOpcoes([]);
    setObservacao("");
    setTeclado("");
  }, [props]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: props.cor_primaria,
            textAlign: "center",
          }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.loja.nome}
            </Typography>
          </Toolbar>
        </AppBar>

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <List>
            <Container sx={{ py: 1, textAlign: "center", mb: 1 }}>
              <img
                src={props.imagem || semImagem}
                alt={props.nome}
                width="100px"
              />
              <Typography
                sx={{ margin: 0, color: "text.primary" }}
                variant="h6">
                {props.nome}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {props.descricao}
              </Typography>
            </Container>

            {props.grupo_opcoes &&
              props.grupo_opcoes.map((grupo_opcao) => (
                <Container sx={{ py: 1 }} key={grupo_opcao.id}>
                  <Stack direction="row" spacing={1}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ margin: 0, padding: 0 }}>
                      {grupo_opcao.nome}
                    </Typography>

                    {grupo_opcao.obrigatorio ? (
                      <Chip
                        label="Obrigatório"
                        color="primary"
                        variant="outlined"
                      />
                    ) : null}

                    {grupo_opcao.min > 0 ? (
                      <Chip
                        label={`Escolha no mínimo ${grupo_opcao.min} opçõe(s)`}
                        variant="outlined"
                      />
                    ) : null}

                    {grupo_opcao.max > 0 ? (
                      <Chip
                        label={`Escolha até ${grupo_opcao.max} opçõe(s)`}
                        variant="outlined"
                      />
                    ) : null}

                    {!grupo_opcao.ativo ? (
                      <Chip
                        label="Indisponível"
                        color="error"
                        variant="outlined"
                      />
                    ) : null}
                  </Stack>

                  {grupo_opcao.opcoes.map((opcao) => (
                    <ListItem
                      key={opcao.id}
                      secondaryAction={
                        grupo_opcao.ativo && opcao.ativo ? (
                          <div>
                            <IconButton
                              edge="start"
                              aria-label="remover"
                              onClick={() =>
                                removerOpcao(grupo_opcao.id, opcao.id)
                              }>
                              <RemoveIcon />
                            </IconButton>
                            <TextField
                              type="text"
                              name={opcao.id}
                              size="small"
                              value={
                                grupoOpcoes.filter(
                                  (grupo) => grupo.opcao === opcao.id
                                ).length
                              }
                              style={{ width: 50 }}
                              InputProps={{
                                readOnly: true,
                                inputProps: {
                                  style: { textAlign: "center" },
                                },
                              }}
                            />
                            <IconButton
                              edge="end"
                              aria-label="adicionar"
                              onClick={() =>
                                addOpcao(grupo_opcao.id, opcao.id)
                              }>
                              <AddIcon />
                            </IconButton>
                          </div>
                        ) : null
                      }>
                      <ListItemText
                        primary={
                          <Typography
                            variant="body2"
                            color={
                              grupo_opcao.ativo && opcao.ativo
                                ? "dark"
                                : "error"
                            }>
                            {opcao.nome}
                          </Typography>
                        }
                        secondary={`${opcao.descricao} ${
                          opcao.preco > 0
                            ? `+ ` +
                              parseFloat(opcao.preco).toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            : ""
                        }`}
                      />
                    </ListItem>
                  ))}
                  <Divider />
                </Container>
              ))}
          </List>

          <Container sx={{ py: 1 }}>
            <TextField
              margin="normal"
              id="observacao"
              label="Observações"
              type="text"
              name="observacao"
              autoComplete="observacao"
              placeholder="Informações no que diz respeito ao produto"
              variant="outlined"
              value={observacao}
              onChange={(event) => {
                setObservacao(event.target.value);
              }}
              onClick={(event) => {
                alterarCampoTeclado(event.target.name);
              }}
              multiline
              fullWidth
            />

            {teclado ? (
              <Box sx={{ mt: 5 }}>
                <Keyboard
                  layoutName={tecladoLayoutNome}
                  layout={tecladoLayout}
                  inputName={teclado}
                  onChange={onChange}
                  onKeyPress={onKeyPress}
                  disableButtonHold={true}
                />
              </Box>
            ) : null}

            {errors.length ? (
              <Alert severity="error">
                {errors.map((error) => error.msg).shift()}
              </Alert>
            ) : (
              ""
            )}
          </Container>

          <Grid
            container
            spacing={2}
            sx={{
              bottom: 0,
              position: "fixed",
              width: "100%",
              mb: 1,
              backgroundColor: "#fff",
            }}>
            <Grid
              item
              xs={9}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <Button
                sx={{
                  width: "200px",
                  height: 50,
                  ml: 1,
                }}
                onClick={() => handleClose()}
                variant="contained"
                color="secondary">
                <Typography inline="true" variant="h6" color="white">
                  Cancelar
                </Typography>
              </Button>
              <Button
                type="submit"
                sx={{
                  width: "400px",
                  height: 50,
                  ml: 1,
                }}
                variant="contained"
                color="primary">
                <Grid container>
                  <Grid item xs={9}>
                    <Typography variant="h6" color="white" align="left">
                      Adicionar item{" "}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="subtitle1" color="white" align="right">
                      {parseFloat(total).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "25px",
                }}>
                <IconButton
                  edge="start"
                  aria-label="remover"
                  onClick={() => alterarQuantidade("remover")}>
                  <RemoveIcon />
                </IconButton>
                <TextField
                  type="text"
                  name="quantidade"
                  size="small"
                  style={{ width: 50 }}
                  value={quantidade}
                  InputProps={{
                    readOnly: true,
                    inputProps: {
                      style: { textAlign: "center" },
                    },
                  }}
                />
                <IconButton
                  edge="end"
                  aria-label="adicionar"
                  onClick={() => alterarQuantidade("adicionar")}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}
